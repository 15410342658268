export const routes = {
  SHOP_ARRAY: [
    "cambio-de-contrasena",
    "productos",
    "producto",
    "carrito",
    "mi-cuenta",
    "pedidos",
    "acceder",
    "registrar",
    "confirmar",
    "servicios",
    "marcas",
    "marca",
    "categorias",
    "categoria",
    "carrito",
    "checkout",
    "pedidos",
    "sucursales",
    "menu",
  ],
  HOME: {
    path: "/",
    title: "Inicio",
  },
  LOGO: {
    path: "/logos",
    title: "Logos",
  },
  LOGIN: {
    path: "/acceder",
    title: "Iniciar sesión",
  },
  DEPOSIT: { path: "/almacen", title: "Todos los productos" },
  CATEGORIES: { path: "/categorias", title: "Categorías" },
  CATEGORY: {
    path: "/categoria/:category",
    base: "/categoria/",
    title: "Categoría",
  },
  CUSTOMER_SERVICE: {
    path: "/soporte-al-cliente",
    title: "Soporte al cliente",
  },
  CUSTOMER_SERVICE_REQUEST_INFO: {
    path: "/servico-al-cliente/solicitud",
    title: "Información de la solicitud",
  },
  BRANDS: { path: "/marcas", title: "Marcas" },
  BRAND: { base: "/marca", path: "/marca/:brand", title: "Marca" },
  ORDERS: { path: "/pedidos", title: "Todos los pedidos" },
  WEB: { path: "/web", title: "Tienda Web" },
  INVENTARY: {
    path: "/inventario",
    title: "Inventario",
  },
  DEPOSIT_CHANGES: {
    path: "/registros-de-almacen",
    title: "Almacén",
  },
  PRICES: { path: "/precios", title: "Precios" },
  ORDERS_CHANGES: {
    path: "/registros-de-pedidos",
    title: "Pedidos",
  },
  SETTINGS_CHANGES: {
    path: "/registros-de-configuraciones",
    title: "Configuración",
  },
  USERS: { path: "/usuarios", title: " Clientes" },
  ORDERS_TO_VERIFY: {
    path: "/ordernes/verificar",
    title: "Por verificar",
  },
  BILLING: { path: "/facturacion", title: "Facturación" },
  PAYMENTS: {
    path: "/configuraciones/tipos&de&pagos",
    title: "Tipos de pagos",
  },
  BS_PAYMENTS: {
    path: "/configuraciones/tipos&de&pagos/bolivares",
    title: "Bolívares",
  },
  ZELLE_PAYMENTS: {
    path: "/configuraciones/tipos&de&pagos/zelle",
    title: "Zelle",
  },
  FLOWCL_PAYMENTS: {
    path: "/configuraciones/tipos&de&pagos/flow-cl",
    title: "Flow.cl",
  },
  MERCADOPAGO_PAYMENTS: {
    path: "/configuraciones/tipos&de&pagos/mercadopago",
    title: "MercadoPago",
  },
  PAYPAL_PAYMENTS: {
    path: "/configuraciones/tipos&de&pagos/paypal",
    title: "PayPal",
  },
  PESOS_PAYMENTS: {
    path: "/configuraciones/tipos&de&pagos/pesos",
    title: "Pesos",
  },
  PICKERS_SETTINGS: {
    path: "/configuraciones/usuarios/gestion",
    title: "Gestionar usuarios",
  },
  SHIPPING_SETTINGS: {
    path: "/configuraciones/costos&de&envios",
    title: "Costos de envíos",
  },
  GENERAL_SETTINGS: {
    path: "/configuraciones/tienda",
    title: "Mis datos",
  },
  COMPANY_PAYMENTS_SETTINGS: {
    path: "/configuraciones/pagos",
    title: "Mis pagos",
  },
  GOOGLE_SETTINGS: {
    path: "/configuraciones/google",
    title: "Google Analytics",
  },
  FACEBOOK_SETTINGS: {
    path: "/configuraciones/facebook",
    title: "Facebook Pixel",
  },
  EMAIL_SETTINGS: {
    path: "/configuraciones/mail",
    title: "Mail personalizado",
  },

  DNS_SETTINGS: {
    path: "/configuraciones/dominio",
    title: "Dominio",
  },
  SEO_SETTINGS: { path: "/configuraciones/SEO", title: "SEO" },
  LOGISTICS: { path: "/logistica", title: "Logística" },
  PICKERS: { path: "/logistica/pickers", title: "Pickers" },
  BS_FINANCE: { path: "/finanzas/bolivares", title: "Bolívares" },
  FINANCE: { path: "/finanzas/:payment", title: "Finanzas" },
  ZELLE_FINANCE: { path: "/finanzas/zelle", title: "Zelle" },
  PAYPAL_FINANCE: { path: "/finanzas/paypal", title: "PayPal" },
  PESOS_FINANCE: { path: "/finanzas/pesos", title: "Pesos" },
  FLOW_FINANCE: { path: "/finanzas/flow", title: "Flow.cl" },
  MERCADOPAGO_FINANCE: { path: "/finanzas/mercadopago", title: "MercadoPago" },
  PLATFORM_STATUS: {
    path: "/plataforma/status",
    title: "Estado de la Plataforma",
  },
  ORDER_INFO: { path: "/pedido", title: "Información del pedido" },
  CREATE_ORDER: { path: "/pedidos/crear", title: "Crear pedido" },
  DISCOUNT_MARKETING: { path: "/marketing/promociones", title: "Promociones" },
  COUPON_MARKETING: { path: "/marketing/cupones", title: "Cupones" },
  NEW_DISCOUNT_MARKETING: {
    path: "/marketing/descuentos/crear",
    title: "Descuentos",
  },
  NEW_COUPON_MARKETING: {
    path: "/marketing/cupones/crear",
    title: "Cupones",
  },
  EMAIL_MARKETING: { path: "/marketing/emails", title: "Emails" },
  NEW_EMAIL_MARKETING: {
    path: "/marketing/emails/crear",
    title: "Crear email",
  },
  NEW_SERVICES: { path: "/servicios/crear", title: "Crear servicio" },
  SERVICES_USER: { path: "/servicios/usuarios", title: "Usuarios" },
  SERVICES: { path: "/servicios", title: "Manejo de Servicios" },
  DEPOSIT_SETTINGS: { path: "/configuraciones/almacen", title: "Almacén" },
  BSALE_SETTINGS: {
    path: "/configuraciones/Integración facturas",
    title: "Integración facturas",
  },

  SHIPPING_POLYGON_MODULE: {
    path: "/configuraciones/modulo&de&alcance",
    title: "Alcances",
  },
  SHIPPING_PREFERENCE: {
    path: "/configuraciones/modulo&de&envio/preferencias",
    title: "Preferencias",
  },
  MAILCHIMP_MARKETING: {
    path: "/marketing/mailchimp",
    title: "Mailchimp",
  },
  RESETPASSWORD: {
    path: "/cambio-de-contrasena",
    title: "Cambio de contraseña",
  },
  ATTRIBUTES: {
    path: "/atributos",
    base: "/atributos",
    title: "Atributos",
  },
  ATTRIBUTE: {
    path: "/atributo/:attribute",
    base: "/atributo",
    title: "Atributo",
  },
  REVIEWS: {
    path: "/review",
    title: "Valoraciones",
  },
  SUBSIDIARIES: {
    path: "/sucursales",
    title: "Horarios / Sucursales",
  },
  SUBSIDIARIES_INFO: {
    path: "/sucursales/:id",
    title: "Horarios / Sucursales",
  },
  TEMPLATES: {
    path: "/plantillas",
    title: "Plantillas",
  },
  MODULE_WEB: {
    path: "/modulo-web",
    title: "Modulo Web",
  },
  MODULE_DYNAMIC_PAGE: {
    path: "/modulo-pagina-dinamica",
    title: "Modulo página dinámica",
  },
  PRODUCT_CARD: {
    path: "/tarjeta-de-producto",
    title: "Tarjeta de producto",
  },
  PLANS: {
    path: "/suscripcion",
    title: "Mi suscripción",
  },
  GOOGLE_SHOPPING: {
    path: "/google-shopping",
    title: "Google Shopping",
  },
  INTEGRATIONS_NATIVE: {
    path: "/integraciones",
    title: "Integraciones oficiales",
  },
  INTEGRATIONS_EXTERNAL_APPS: {
    path: "/integraciones/external-apps/webhook",
    title: "Webhook",
  },
  INTEGRATIONS_SHIPPING_MODULE: {
    path: "/integraciones/modulo&de&envio&propios",
    title: "Envíos propios",
  },
  INTEGRATIONS_FACEBOOK_PIXEL: {
    path: "/integraciones/facebook-pixel",
    title: "Configuracion de integracion con Facebook Pixel",
  },
  INTEGRATIONS_FUDO: {
    path: "/integraciones/fudo",
    title: "Configuracion de integracion con Fudo",
  },
  INTEGRATIONS_GOOGLE_ANALYTICS: {
    path: "/integraciones/google-analytics",
    title: "Configuracion de integracion con Google Analytics",
  },
  INTEGRATIONS_GOOGLE_TAG_MANAGER: {
    path: "/integraciones/google-tag-manager",
    title: "Configuracion de integracion con Google TagManager",
  },
  INTEGRATIONS_INGEFACTURAS: {
    path: "/integraciones/IngeFacturas",
    title: "Configuracion de integracion con IngeFacturas",
  },
  INTEGRATIONS_MAILCHIMP: {
    path: "/integraciones/MAILCHIMP",
    title: "Configuracion de integracion con Mailchimp",
  },
  INTEGRATIONS_ORACLE: {
    path: "/integraciones/oracle",
    title: "Configuracion de integracion con Oracle",
  },
  INTEGRATIONS_TOTEAT: {
    path: "/integraciones/Toteat",
    title: "Configuracion de integracion con Toteat",
  },
  INTEGRATIONS_GETNET: {
    path: "/integraciones/getNet",
    title: "Configuracion de integracion con GetNet",
  },
  INTEGRATIONS_MERCADOPAGO: {
    path: "/integraciones/mercadoPago",
    title: "Configuracion de integracion con Mercado pago",
  },
  INTEGRATIONS_PEDIDOSYA: {
    path: "/integraciones/pedidosya",
    title: "Configuracion de integracion con PedidosYa",
  },
  INTEGRATIONS_CABIFY: {
    path: "/integraciones/cabify",
    title: "Configuracion de integracion con Cabify",
  },
  INTEGRATIONS_UBER: {
    path: "/integraciones/uber",
    title: "Configuracion de integracion con Uber",
  },
  // SHIPPING_SETTINGS: {
  //   path: "/configuraciones/medios-de-envio",
  //   title: "Configuración de medios de envío",
  // },
  ADDITIONAL: {
    path: "/adicionales",
    base: "/adicionales",
    title: "Adicionales",
  },
  MENU: {
    path: "/ver-qrs",
    title: "Ver mis QR",
  },
  QR_SURVEY: {
    path: "/encuesta_qr",
    title: "Encuesta QR",
  },
  BOOKINGS: {
    path: "/bookings",
    title: "Gestion de reservas",
  },
  INCIDENTS: {
    path: "/incidencias",
    title: "Incidencias con Pedidos",
  },
  SALES: {
    path: "/ventas",
    title: "Ventas",
  },
};

export const SideBarIcons = {
  coupon: "https://s3.amazonaws.com/lumarketo.cl/icons8-coupon-64.png",
  InstagramShopping:
    "https://s3.amazonaws.com/lumarketo.cl/icons8-instagram-96.png",
  FacebookShopping:
    "https://s3.amazonaws.com/lumarketo.cl/icons8-facebook-96.png",
  Checkout: "https://s3.amazonaws.com/lumarketo.cl/icons8-shopping-cart-96.png",
  GoogleShopping: "https://s3.amazonaws.com/lumarketo.cl/icons8-google-96.png",
  Home: "https://dgdvd9d8e5bk8.cloudfront.net/photos/icons8-home-32.png",
  Catalogo: "https://s3.amazonaws.com/lumarketo.cl/icons8-tags-48.png",
  Ordenes:
    "https://dgdvd9d8e5bk8.cloudfront.net/photos/icons8-purchase-order-50.png",
  Finanzas:
    "https://dgdvd9d8e5bk8.cloudfront.net/photos/finanzasLumarketo-Finanzas-32x32.png",
  logistica:
    "https://dgdvd9d8e5bk8.cloudfront.net/photos/icons8-document-delivery-50.png",
  Servicioalcliente:
    "https://s3.amazonaws.com/lumarketo.cl/icons8-support-98.png",
  marketing:
    "https://dgdvd9d8e5bk8.cloudfront.net/photos/icons8-marketing-64.png",
  Registros:
    "https://dgdvd9d8e5bk8.cloudfront.net/photos/registrosLumarketo-Registros-32x32.png",
  tiendaweb:
    "https://dgdvd9d8e5bk8.cloudfront.net/photos/icons8-store-front-32.png",
  config:
    "https://dgdvd9d8e5bk8.cloudfront.net/photos/configuracionesLumarketo-configutaciones-32x32.png",
  logo: "https://s3.amazonaws.com/lumarketo.cl/lumarketo-white-franciscus.svg",
  integrations: "https://s3.amazonaws.com/lumarketo.cl/icons8-rest-api-96.png",
};

export default routes;
