import config from "../config";
import { get, put, Delete, post } from "../http";

export async function getByIdCompany(id, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.emailMicroserviceUrlApi}/email/byIdCompany?id=${id}`,
    headers
  );
}

async function getById(id, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(`${config.emailMicroserviceUrlApi}/email?id=${id}`, headers);
}

async function create(email, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    ...email,
  };
  return await post(`${config.emailMicroserviceUrlApi}/email/`, headers, body);
}

async function update(email, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    ...email,
  };
  return await put(`${config.emailMicroserviceUrlApi}/email/`, headers, body);
}

async function remove(email, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    ...email,
  };
  return await Delete(
    `${config.emailMicroserviceUrlApi}/email/`,
    headers,
    body
  );
}

const sendOrderEmail = async (data, token) => {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    request: { ...data },
  };

  return await post(
    `${config.emailMicroserviceUrlApi}/email/ordenCompra`,
    headers,
    body
  );
};
const sendTestEmail = async (email, idCompany) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    request: {
      to: email,
      subject: "Test",
      idCompany,
    },
  };

  return await post(
    `${config.emailMicroserviceUrlApi}/email/test`,
    headers,
    body
  );
};

const sendOrderReadyEmail = async (data, token) => {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  const body = {
    request: { ...data },
  };

  return await post(
    `${config.emailMicroserviceUrlApi}/email/ordenLista`,
    headers,
    body
  );
};

const sendOrderDispatchedEmail = async (data, token) => {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  const body = {
    request: { ...data },
  };

  return await post(
    `${config.emailMicroserviceUrlApi}/email/ordenEntregada`,
    headers,
    body
  );
};

const sendEmail = async ({ idCompany, to, subject, html }, token) => {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  const body = {
    request: {
      idCompany,
      to,
      subject,
      html,
    },
  };

  return await post(
    `${config.emailMicroserviceUrlApi}/email/send`,
    headers,
    body
  );
};

const sendSupportEmail = async (
  { idCompany, email, subject, chatText, requestId },
  token
) => {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  const body = {
    request: {
      idCompany,
      email,
      subject,
      chatText,
      requestId,
    },
  };

  return await post(
    `${config.emailMicroserviceUrlApi}/email/support`,
    headers,
    body
  );
};

const getEmailOfNewsletter = async (idCompany) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.emailMicroserviceUrlApi}/newsletter/?idCompany=${idCompany}`,
    headers
  );
};

async function getStatus() {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(`${config.emailMicroserviceUrlApi}/system/status`, headers);
}

export const sendResetPassword = async (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    request: { ...data },
  };

  return post(
    `${config.emailMicroserviceUrlApi}/email/cambioContrasena`,
    headers,
    body
  );
};

export const sendDeclineOrder = ({
  companyId,
  email,
  name,
  rejectReason,
  logo,
  companyName,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    request: {
      companyId,
      email,
      name,
      rejectReason,
      logo,
      companyName,
    },
  };

  return post(
    `${config.emailMicroserviceUrlApi}/email/declineOrder`,
    headers,
    body
  );
};

export const sendWithdrawalEmail = (orderId, date) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    orderId,
    type: "withdrawalEmail",
    date,
  };

  return post(`${config.baseUrl}/genericScheduleEvent`, headers, body);
};

const functions = {
  sendOrderEmail,
  getByIdCompany,
  create,
  remove,
  update,
  getById,
  sendOrderReadyEmail,
  sendOrderDispatchedEmail,
  getEmailOfNewsletter,
  sendEmail,
  sendSupportEmail,
  getStatus,
  sendTestEmail,
  sendResetPassword,
};
export default functions;
