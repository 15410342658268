import storage from "../utils/storage";

const lumarketo = storage.get();

const initialState = {
  web: {
    index: 0,
    subIndex: 0,
    components: [],
    desktopComponents: [],
    mobileComponents: [],
    tabletComponents: [],
    selectedComponent: null,
    updated: false,
    editTab: 0,
    showConfigDialog: false,
    sideBarDestinationDragaggleId: -1,
    sideBarSourceDragaggleId: -1,
  },
  fonts: [],
  imgHistory: [],
  addresses: [],
  checkout: {
    services: [],
  },
  services: [],
  discount: [],
  email: [],
  ui: {
    companyId: "",
    loading: false,
    resolution: "desktop",
    isGoogleApiLoaded: false,
    webHome: {
      index: -1,
      subIndex: 0,
    },
    heightPixels: 0,
    isPro: false,
    subsidiary: "",
  },
  user: {
    connected: false,
    data: lumarketo && lumarketo.user ? lumarketo.user : undefined,
    requestsLogin: [],
    users: [],
  },
  products: [],
  subsidiaries: [],
  company: {
    firstScheduledTimeSlot: 60,
    useRightMiniCart: false,
    useWithdrawalEmailScheduling: false,
    useDeliveryPin: false,
    useCheckoutInvoiceModal: false,
    useMultiStore: false,
    loyaltyPointsComponent: {
      loyaltyPointsHeaderTextColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      loyaltyPointsHeaderBackgroundColor: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
    },
    loyaltyName: "",
    useLoyaltyPoints: false,
    Integrations: {
      toteat: false,
      fudo: false,
      oracle: false,
    },
    UseShippingScheduling: false,
    UseNubefoodFooter: false,
    UseDeliveryModal: false,
    UseRetryShipping: false,
    UseShippingPreference: false,
    ShippingPreference: [],
    ModalProductCard: {
      textColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      backgroundColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      titleColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      descriptionColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      priceColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      priceWithDiscountColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      usePriceWithDiscountColor: false,
      backgroundColorButton: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      borderColorButton: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      textColorButton: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      qtyColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
    },
    MiniCart: {
      backgroundColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      textColor: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
      inputBorderColor: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
      inputBackgroundColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      buttonBackgroundColor: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
      buttonTextColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
    },

    SubHeader: {
      textColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      selectedTextColor: {
        r: 128,
        g: 0,
        b: 128,
        a: 1,
      },
      backgroundColor: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
    },

    UseStreetNumberValidation: false,
    UseQrSurvey: false,
    FacebookDomainVerification: "",
    AutoLogout: true,
    TicketType: 1,
    UseHTMLSlide: false,
    HTMLSlide: "",
    HTMLSlideProps: [],
    Title: "",
    UseBackgroundImage: false,
    PrintOrder: false,
    UseHome: false,
    AddressInfo: undefined,
    UseMultiStock: false,
    Whatsapp: {
      url: "",
      direction: "right",
      activate: false,
    },
    UseTiendaHouse: false,
    BalanceDays: 0,
    FacebookPixelId: "",
    UseEmailConfig: false,
    ProductCard: {},
    UseGridPLP: false,
    Quotes: 1,
    BsaleToken: "",
    GoogleAnalytics: {
      GAID: "",
      GTMID: "",
    },
    Typography: {
      title: {
        font: "",
        color: "",
      },
      subtitle: {
        font: "",
        color: "",
      },
      price: {
        font: "",
        color: "",
      },
      description: {
        font: "",
        color: "",
      },
      button: {
        font: "",
        color: "",
      },
      cart: {
        font: "",
        color: "",
      },
    },
    UseBrandSlider: false,
    UseNewsletterPopup: false,
    Home: [],
    WebLink: "",
    Slides: [],
    Brands: [],
    Categories: [],
    Payments: [],
    SocialNetworks: [
      {
        Name: "Instagram",
        Icon: "fa-instagram",
        Link: "",
      },
      {
        Name: "Facebook",
        Icon: "fa-facebook",
        Link: "",
      },
    ],
    EmailConfig: {
      port: 465,
      user: "",
      secure: true,
      pass: "",
      host: "",
      imapHost: "",
      imapPort: 993,
    },
    SEO: {
      MetaTags: [],
    },
    Address: "",
    Country: "",
    Colors: [],
    DesktopLogo: "",
    WhiteDesktopLogo: "",
    WhiteMobileLogo: "",
    LoadingLogo: "",
    Email: "",
    FreeShipping: 0,
    Lat: 0,
    Lng: 0,
    FavIcon: "",
    Logo: "",
    MobileLogo: "",
    Tlf: "",
    Name: "",
    NavBar: [],
    ProductTabs: [],
    Header: {
      color: "",
      secondColor: "",
      upperBarColor: "",
      upperBarColorText: "",
      upperBarAlign: "",
      navBar: [],
      headerTemplate: 1,
    },
    Footer: {
      color: "",
      secondColor: "",
      upperText: "",
      lowerText: "",
      footerTemplate: 1,
    },
    Body: {
      backgroundColor: "",
    },
    Newsletter: {
      Desktop: "",
      ResolutionDesktop: "",
      Tablet: "",
      ResolutionTablet: "",
      Mobile: "",
      ResolutionMobile: "",
      Link: "",
      Title: "",
      SubTitle: "",
      Conditions: "",
    },
    SKUNumber: 0,
    Services: {
      Modalities: [],
      Durations: [],
    },
    ShippingCost: 0,
    UseInfiniteScroll: false,
    _id: "",
    UseChildrenForProducts: false,
    UsePayments: {
      useBolivar: false,
      useZelle: false,
      usePaypal: false,
      useFlowCL: false,
      useMercadopago: false,
    },
    CustomerService: {
      dialog: {
        desktop: {
          img: "",
          resolution: "",
        },
        tablet: {
          img: "",
          resolution: "",
        },
        mobile: {
          img: "",
          resolution: "",
        },
      },
    },
    SKUCollections: [],
    UseShippingPolicy: false,
    UseSubsidiary: false,
  },
  categories: {
    updated: false,
  },
  brands: {
    updated: false,
  },
  deposit: {
    product: {
      _id: "",
      Category: "",
      Name: "",
      Stars: 0,
      Description: "",
      Details: "",
      Reviews: [],
      IdCompany: "",
      Brand: "",
      Subcategory: "",
      Show: false,
      CreatedDate: new Date().toISOString(),
      UpdatedDate: new Date().toISOString(),
      Children: [],
      Unit: "Un",
      AdditionalGroups: [],
      Additionals: [],
    },
    mode: "create",
    changes: [],
    page: 1,
    rowsPerPage: 20,
    order: "asc",
    orderBy: "Name",
  },
  orders: {
    didntReviewed: 0,
    list: [],
    productsToDispatch: [],
    view: [],
  },
  ordersToVerify: {
    orders: [],
    list: [],
  },
  billing: [],
  inventaryRecord: {
    list: [],
    auxiliar: {
      _id: "",
      IdCompany: "",
      IdUser: "",
      Details: "",
      Accepted: false,
      Date: "",
      Objects: [],
    },
    open: false,
  },
  customerService: [],
  depositChanges: [],
  units: [],
  platformStatus: {
    payment: {
      timestamp: 0,
      ok: false,
      name: "Pagos",
    },
    customer: {
      timestamp: 0,
      ok: false,
      name: "Atención al cliente",
    },
    services: {
      timestamp: 0,
      ok: false,
      name: "Servicios",
    },
    shipping: {
      timestamp: 0,
      ok: false,
      name: "Envíos",
    },
    email: {
      timestamp: 0,
      ok: false,
      name: "Email",
    },
    product: {
      timestamp: 0,
      ok: false,
      name: "Productos",
    },
    order: {
      timestamp: 0,
      ok: false,
      name: "Pedidos",
    },
    user: {
      timestamp: 0,
      ok: false,
      name: "Usuarios",
    },
    company: {
      timestamp: 0,
      ok: false,
      name: "Compañias",
    },
    address: {
      timestamp: 0,
      ok: false,
      name: "Direcciones",
    },
    records: {
      timestamp: 0,
      ok: false,
      name: "Registros",
    },
    finance: {
      timestamp: 0,
      ok: false,
      name: "Finanzas",
    },
  },
  seo: {
    metaTags: [],
    updated: false,
  },
  shipping: [],
};

export default initialState;
