/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCompany, getCompany } from "../../../../reducers/companySlice";
import {
  createToteat,
  deleteToteat,
  getToteat,
  updateToteat,
} from "../../../../utils/services/company";
import { useDispatch } from "react-redux";
import {
  selectLoadingUI,
  loadingOn,
  loadingOff,
} from "../../../../reducers/uiSlice";
import { Notification } from "../../../../containers/notification";
import { useSnackbar } from "notistack";
import { selectUser } from "../../../../reducers/userSlice";
import {
  selectChangesDeposit,
  setChanges,
} from "../../../../reducers/depositSlice";
import { createChangeRecord } from "../../../../reducers/depositChangesSlice";
import dateUtils from "../../../../utils/date";
import { useNavigate } from "react-router-dom";

// CSS

import styles from "./styles.module.css";

//MATERIAL
import InputComponent from "../../../inputs";
import Toolbar from "../../../card/toolbar";
import jwt from "jsonwebtoken";
import config from "../../../../utils/config";
import { Checkbox, Tab, Tabs } from "@material-ui/core";
import { selectSubsidiaries } from "../../../../reducers/subsidiarySlice";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const logo =
  "https://luma-photos.s3.amazonaws.com/toteat-black-4k.e34ff285.png";

export default function ToteatComponent() {
  const [restaurantId, setRestaurantId] = useState("");
  const [localId, setLocalId] = useState("");
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [isTest, setIsTest] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [subsidiaryId, setSubsidiaryId] = useState();
  const [useMockOrder, setUseMockOrder] = useState(false);
  const [mockProductCode, setMockProductCode] = useState("");
  const subsidiaries = useSelector(selectSubsidiaries);
  const company = useSelector(selectCompany);
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoadingUI);
  const changes = useSelector(selectChangesDeposit);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (subsidiaries && subsidiaries.length > 0 && !subsidiaryId)
      setSubsidiaryId(subsidiaries[0]._id);
  }, [subsidiaries]);

  useEffect(() => {
    if (company && company._id !== "" && subsidiaryId && subsidiaryId !== "")
      initialize();
  }, [company, subsidiaryId]);

  const initialize = async () => {
    const found = await getToteat(company._id, subsidiaryId).catch((err) => {
      console.log(err);
      return null;
    });

    if (found) {
      if (found.restaurantId && found.restaurantId !== "")
        setRestaurantId(jwt.verify(found.restaurantId, config.jwtSecret));
      else setRestaurantId("");
      if (found.localId && found.localId !== "")
        setLocalId(jwt.verify(found.localId, config.jwtSecret));
      else setLocalId("");
      if (found.userId && found.userId !== "")
        setUserId(jwt.verify(found.userId, config.jwtSecret));
      else setUserId("");
      if (found.token && found.token !== "")
        setToken(jwt.verify(found.token, config.jwtSecret));
      else setToken("");
      if (found.subsidiaryId && found.subsidiaryId !== "")
        setSubsidiaryId(found.subsidiaryId);
      setIsTest(found.isTest);

      if (found.paymentType && found.paymentType !== "")
        setPaymentType(jwt.verify(found.paymentType, config.jwtSecret));
      else setPaymentType("");
      if (found.mockProductCode && found.mockProductCode !== "")
        setMockProductCode(found.mockProductCode);
      setUseMockOrder(!!found.useMockOrder);
    }
  };

  const updateChanges = (fieldName, newValue) => {
    const newChanges = [];
    newChanges.push(...changes);
    var index = -1;
    const change = {
      text: "",
      oldValue: null,
      newValue: null,
      date: dateUtils.getUTC().slice(0, 19).replace("T", " "),
    };

    change.newValue = newValue;
    change.oldValue = company && company[fieldName] ? company[fieldName] : "";

    switch (fieldName) {
      case "ClientId":
        change.text = "Cambios en el clientId de fudo";
        break;
      case "ClientSecret":
        change.text = "Cambios en el clientId de fudo";
        break;
      case "paymentType":
        change.text = "Cambios en el tipo de pago de fudo";
        break;
      case "restaurantId":
        change.text = "Cambios en el restaurantId de toteat";
        break;
      case "localId":
        change.text = "Cambios en el localId de toteat";
        break;
      case "useMockOrder":
        change.text = "Cambios en el uso de ordenes de prueba";
        break;
      case "mockProductCode":
        change.text = "Cambios en el código de producto de prueba";
        break;
      default:
        break;
    }

    index = newChanges.findIndex(
      (item) => item.text.toString() === change.text.toString()
    );

    if (index !== -1) {
      change.oldValue = newChanges[index].oldValue;
      newChanges[index] = change;
    } else {
      newChanges.push(change);
    }
    dispatch(setChanges(newChanges));
  };

  const handleCancel = () => initialize();

  const handleSave = async () => {
    dispatch(loadingOn());
    const found = await getToteat(company._id, subsidiaryId).catch((err) => {
      console.log(err);
      return null;
    });
    if (found && !found.restaurantId) {
      createToteat({
        restaurantId: jwt.sign(restaurantId, config.jwtSecret),
        localId: jwt.sign(localId, config.jwtSecret),
        userId: jwt.sign(userId, config.jwtSecret),
        token: jwt.sign(token, config.jwtSecret),
        paymentType: jwt.sign(paymentType, config.jwtSecret),
        companyId: company._id,
        subsidiaryId,
        isTest: isTest,
        useMockOrder,
        mockProductCode,
      })
        .then((res) => {
          dispatch(loadingOff());
          getCompany(company._id, dispatch);
          enqueueSnackbar(
            Notification({
              text: "Datos de la tienda actualizados",
              variant: "success",
              withDetails: false,
            })
          );
          setUpdated(false);
          createChangeRecord(
            {
              user: user,
              username: user.username,
              fullName: `${user.firstName} ${user.lastName}`,
              idUser: user.id,
              idCompany: company._id,
              idObject: company._id,
              module: "settings",
              submodule: "toteat",
              createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
              changes: changes,
            },
            user.token,
            dispatch
          ).catch((err) => console.log(err));
        })
        .catch((err) => {
          enqueueSnackbar(
            Notification({
              text: "Error al actualizar datos de la tienda",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
    } else if (found && found.restaurantId)
      updateToteat({
        restaurantId: jwt.sign(restaurantId, config.jwtSecret),
        localId: jwt.sign(localId, config.jwtSecret),
        userId: jwt.sign(userId, config.jwtSecret),
        token: jwt.sign(token, config.jwtSecret),
        paymentType: jwt.sign(paymentType, config.jwtSecret),
        subsidiaryId,
        companyId: company._id,
        isTest: isTest,
        useMockOrder,
        mockProductCode,
      })
        .then((res) => {
          dispatch(loadingOff());
          getCompany(company._id, dispatch);
          enqueueSnackbar(
            Notification({
              text: "Datos de la tienda actualizados",
              variant: "success",
              withDetails: false,
            })
          );
          setUpdated(false);
          createChangeRecord(
            {
              user: user,
              username: user.username,
              fullName: `${user.firstName} ${user.lastName}`,
              idUser: user.id,
              idCompany: company._id,
              idObject: company._id,
              module: "settings",
              submodule: "toteat",
              createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
              changes: changes,
            },
            user.token,
            dispatch
          ).catch((err) => console.log(err));
        })
        .catch((err) => {
          enqueueSnackbar(
            Notification({
              text: "Error al actualizar datos de la tienda",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
  };

  const handleRemove = async () => {
    dispatch(loadingOn());
    deleteToteat(company._id)
      .then(async (res) => {
        dispatch(loadingOff());
        getCompany(company._id, dispatch);
        await initialize();
        enqueueSnackbar(
          Notification({
            text: "Datos de la tienda actualizados",
            variant: "success",
            withDetails: false,
          })
        );
        setUpdated(false);
        createChangeRecord(
          {
            user: user,
            username: user.username,
            fullName: `${user.firstName} ${user.lastName}`,
            idUser: user.id,
            idCompany: company._id,
            idObject: company._id,
            module: "settings",
            submodule: "toteat",
            createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
            changes: changes,
          },
          user.token,
          dispatch
        ).catch((err) => console.log(err));
      })
      .catch((err) => {
        enqueueSnackbar(
          Notification({
            text: "Error al actualizar datos de la tienda",
            variant: "error",
            withDetails: false,
          })
        );
        dispatch(loadingOff());
      });
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className={styles.parentContainer}>
      <button className={styles.backButton} onClick={() => handleClose()}>
        <span>
          <ArrowBackIcon />
        </span>
        Regresar
      </button>
      <div className={styles.infoContainer}>
        <div className={styles.titleLogoContainer}>
          <Toolbar title="Toteat" />
          <img src={logo} alt="logo" className={styles.imageElement} />
        </div>
        <div className={styles.infoDataContainer}>
          <p className={styles.textDescription}>
            Con las credenciales de TOTEAT podrás integrar tu sistema
            administrativo de restaurante en uno solo. Esto significa que los
            pedidos que recibas a través de NubeFood serán recibidos
            automáticamente en tu sistema TOTEAT, permitiéndote tener un control
            centralizado de todas las ventas diarias, así como también la
            generación de una boleta por cada pedido
          </p>
          {subsidiaries && (
            <Tabs
              value={subsidiaryId}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, newValue) => setSubsidiaryId(newValue)}
              aria-label="disabled tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              {subsidiaries.map((item) => (
                <Tab label={item.Name} value={item._id} />
              ))}
            </Tabs>
          )}
          <div className={styles.inputStackContainer}>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Restaurant ID</label>

              <InputComponent
                placeholder="Ej. 12345678901234"
                type="text"
                handler={(e) => {
                  setRestaurantId(e.target.value);
                  updateChanges("restaurantId", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={restaurantId === ""}
                value={restaurantId}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Local ID</label>

              <InputComponent
                placeholder="Ej. 12345678901234"
                type="text"
                handler={(e) => {
                  setLocalId(e.target.value);
                  updateChanges("LocalId", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={localId === ""}
                value={localId}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>User ID</label>

              <InputComponent
                placeholder="Ej. 12345678901234"
                type="text"
                handler={(e) => {
                  setUserId(e.target.value);
                  updateChanges("UserId", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={userId === ""}
                value={userId}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Token</label>

              <InputComponent
                placeholder="Ej. 12345678901234"
                type="text"
                handler={(e) => {
                  setToken(e.target.value);
                  updateChanges("Token", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={token === ""}
                value={token}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Payment Type</label>

              <InputComponent
                placeholder="Ej. 12345678901234"
                type="text"
                handler={(e) => {
                  setPaymentType(e.target.value);
                  updateChanges("PaymentType", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={paymentType === ""}
                value={paymentType}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Activar modo prueba</label>

              <Checkbox
                checked={isTest}
                onChange={(e) => {
                  setIsTest(e.target.checked);
                  updateChanges("IsTest", e.target.value);
                  setUpdated(true);
                }}
                value={"isTest"}
                style={{ color: "#122526" }}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>
                Crear orden provisional cuando se recibe un pedido con productId
                erróneo
              </label>
              <Checkbox
                checked={useMockOrder}
                onChange={(e) => {
                  setUseMockOrder(e.target.checked);
                  updateChanges("useMockOrder", e.target.checked);
                  setUpdated(true);
                }}
                value={"useMockOrder}"}
                style={{ color: "#122526" }}
              />
            </div>
            {useMockOrder ? (
              <div className={styles.inputContainer}>
                <label className={styles.labelInput}>
                  ProductCode para orden provisional
                </label>
                <InputComponent
                  placeholder="Ej. 12345678901234"
                  type="text"
                  handler={(e) => {
                    setMockProductCode(e.target.value);
                    updateChanges("mockProductCode", e.target.value);
                    setUpdated(true);
                  }}
                  disabled={loading}
                  validation={mockProductCode === ""}
                  value={mockProductCode}
                />
              </div>
            ) : null}
            <div className={styles.buttonsContainer}>
              {token !== "" ? (
                <button
                  onClick={() => {
                    handleRemove();
                  }}
                  className={styles.deleteButton}
                >
                  Eliminar
                </button>
              ) : null}
              <button
                disabled={!updated}
                onClick={() => {
                  setUpdated(true);
                  handleCancel();
                }}
                className={styles.cancelButton}
              >
                Cancelar
              </button>
              <button
                disabled={!updated}
                className={styles.confirmButton}
                onClick={() => {
                  setUpdated(true);
                  handleSave();
                }}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
