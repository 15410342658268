/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import jwt from "jsonwebtoken";
import { useSnackbar } from "notistack";
import classnames from "classnames";

//MATERIAL-UI
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import StoreIcon from "@material-ui/icons/Store";
import CloseIcon from "@material-ui/icons/Close";

//IMPORTS
import routes from "../../constants/routes";
import { success, logout } from "../../reducers/userSlice";
import UserHeaderComponent from "../../components/users/userHeader.component";
import ChileModulesList from "../../components/sideBar/modulesList.component";
import { Notification } from "../../containers/notification";
import { selectUser } from "../../reducers/userSlice";
import { selectCompany, getCompany } from "../../reducers/companySlice";
import {
  loadingOn,
  loadingOff,
  selectLoadingUI,
  setCompanyId,
  selectCompanyId,
  setHeightPixel,
  selectResolution,
  setIsPro,
  selectSubsidiary,
  setSubsidiary,
} from "../../reducers/uiSlice";
import config from "../../utils/config";
import storage from "../../utils/storage";
import AudioMp3 from "../../assets/mixkit-bell-notification-933.mp3";

//CSS
import styles from "./app.module.css";

/**CONSTANTS  */
import { Link } from "@material-ui/core";
import {
  getCompanyBySubdomain,
  getTemplateHistory,
  updateCompany,
  updateDynamicPage,
  updateTemplateHistory,
  getDynamicPage,
} from "../../utils/services/company";
import Routes from "../../constants/routes";
import webConstants, { resolutions } from "../../constants/web.constants";
import useQuery from "../../utils/hooks/queryParameters";
import ModuleWebSideBar from "../../components/sideBar/moduleWeb";
import ModuleWebToolbar from "../web/moduleWebToolbar";
import InfoToolbar from "../web/infoToolbar";
import {
  selectDesktopComponents,
  selectMobileComponents,
  selectTabletComponents,
  selectUpdated,
  setDesktopComponents,
  setMobileComponents,
  setTabletComponents,
  setUpdated,
} from "../../reducers/webSlice";
import _orderBy from "lodash/orderBy";
import Axios from "axios";
import { WebSocketDemo } from "../websocket";
import { selectSubsidiaries } from "../../reducers/subsidiarySlice";
import { SelectComponent } from "../../components/inputs";
import PaymentUpperHeader from "./paymentUpperHeader";

function App(props) {
  const { children } = props;
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(true);

  const [notFound, setNotFound] = useState(false);
  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);
  const loadingUI = useSelector(selectLoadingUI);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const companyId = useSelector(selectCompanyId);
  const [dynamicPage, setDynamicPage] = useState();
  const [mobileOpen, setMobileOpen] = useState(false);
  const query = useQuery(useLocation);
  const c = query.get("c");
  const templateHistoryId = query.get("id");
  const pageUrl = query.get("page");
  const header = document.getElementById("header");
  const updated = useSelector(selectUpdated);
  const desktopComponents = useSelector(selectDesktopComponents);
  const mobileComponents = useSelector(selectMobileComponents);
  const tabletComponents = useSelector(selectTabletComponents);
  const resolution = useSelector(selectResolution);
  const subsidiary = useSelector(selectSubsidiary);
  const subsidiaries = useSelector(selectSubsidiaries);
  const userLocalStorageCheck = storage.get()?.user;

  useEffect(() => {
    dispatch(setIsPro(true));
  }, []);

  useEffect(() => {
    if (((subsidiary && subsidiary === "") || !subsidiary) && user) {
      if (user?.subsidiaries?.length > 0) {
        if (user?.subsidiaries?.find((item) => item === "all"))
          dispatch(setSubsidiary("all"));
        else dispatch(setSubsidiary(user?.subsidiaries[0]));
      } else if (subsidiaries?.length === 1) {
        dispatch(setSubsidiary(subsidiaries[0]._id));
      } else if (user?.email === "lumarketo.desarrollo@gmail.com") {
        dispatch(setSubsidiary("all"));
      } else {
        dispatch(setSubsidiary("all"));
      }
    } else if (!user) {
      dispatch(setSubsidiary(""));
    }
  }, [user?.subsidiaries, subsidiaries]);

  useEffect(() => {
    let cancelToken = Axios.CancelToken.source();
    let unmounted = false;
    function getCompany() {
      const wildcard = window.location.hostname.split(".")[0];
      getCompanyBySubdomain(
        config.nodeEnv !== "development" ? wildcard : "localhost",
        cancelToken.token
      )
        .then((res) => {
          if (res && res.CompanyId && !unmounted) {
            dispatch(setCompanyId(res.CompanyId));
            setNotFound(false);
          } else if (!res) setNotFound(true);
        })
        .catch((err) => {
          if (!unmounted) {
            console.log(err);
          }
          return null;
        });
    }
    getCompany();
    return () => {
      unmounted = true;
      cancelToken.cancel();
    };
  }, []);

  useEffect(() => {
    if (companyId && companyId !== "" && userLocalStorageCheck)
      getCompany(companyId, dispatch);
  }, [companyId, userLocalStorageCheck]);

  useEffect(() => {
    if (companyId && companyId !== "") checkUserData();
  }, [companyId]);

  const checkUserData = () => {
    dispatch(loadingOn());
    const lumarketo = storage.get();
    if (!lumarketo)
      storage.set({
        user: undefined,
      });
    const userLocalStorage = lumarketo ? lumarketo.user : undefined;
    if (userLocalStorage) {
      success(userLocalStorage, companyId, subsidiary, dispatch);
    }
    dispatch(loadingOff());
  };
  useEffect(() => {
    if (user?.token) {
      jwt.verify(user.token, config.jwtSecret, function (err, decoded) {
        if (err && err.name === "TokenExpiredError" && company.AutoLogout) {
          logout(dispatch);
          enqueueSnackbar(
            Notification({
              text: "Su sesión ha expirado.",
              variant: "info",
              withDetails: false,
            })
          );
          navigate(routes.LOGIN.path);
        }
      });
    }
  }, [user, loadingUI, company]);

  useLayoutEffect(() => {
    getHeight();
  }, [loadingUI, location.pathname, header?.clientHeight]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [loadingUI, location.pathname]);

  useEffect(() => {
    async function get() {
      getData();
    }
    if (
      company &&
      company._id !== "" &&
      location.pathname.includes(Routes.MODULE_WEB.path)
    )
      get();
  }, [company?._id, location.pathname, location.search]);

  const getHeight = () => {
    var height = 0;
    if (header) {
      height += header?.clientHeight;
      dispatch(setHeightPixel(height));
    }
  };

  const handleDrawerOpen = () => setOpenDrawer(true);

  const handleDrawerClose = () => setOpenDrawer(false);

  const getSource = async () => {
    if (templateHistoryId && templateHistoryId !== "") {
      const data = await getTemplateHistory(templateHistoryId).catch((err) => {
        console.log(err);
        return null;
      });
      return data ? data.home : null;
    } else if (
      (!templateHistoryId || templateHistoryId === "") &&
      pageUrl &&
      pageUrl !== ""
    ) {
      const data = await getDynamicPage(pageUrl, company._id).catch((err) => {
        console.log(err);
        return null;
      });
      if (data) {
        setDynamicPage(data);
        return data.components;
      }
    } else if (
      (!templateHistoryId || templateHistoryId === "") &&
      (!pageUrl || pageUrl === "")
    )
      return company.Home;
  };

  async function getData() {
    const ordered = _orderBy(await getSource(), ["index"], ["asc"]);
    const desktop = ordered.filter(
      (item) =>
        (item && item.resolution && item.resolution === resolutions.desktop) ||
        (!item.resolution && resolution === resolutions.desktop)
    );
    dispatch(setDesktopComponents(desktop));
    const tablet = ordered.filter(
      (item) =>
        item && item.resolution && item.resolution === resolutions.tablet
    );
    dispatch(setTabletComponents(tablet));
    const mobile = ordered.filter(
      (item) =>
        item && item.resolution && item.resolution === resolutions.mobile
    );
    dispatch(setMobileComponents(mobile));
  }

  const handleHomeSave = async () => {
    var newCompany = { ...company };
    var newHome = [];
    dispatch(loadingOn());

    newHome.push(
      ...desktopComponents.map((item, index) => {
        var { icon, ...newItem } = item;
        newItem = { ...newItem, index };
        return newItem;
      })
    );
    newHome.push(
      ...tabletComponents.map((item, index) => {
        var { icon, ...newItem } = item;
        newItem = { ...newItem, index };
        return newItem;
      })
    );
    newHome.push(
      ...mobileComponents.map((item, index) => {
        var { icon, ...newItem } = item;
        newItem = { ...newItem, index };
        return newItem;
      })
    );

    if (
      (!templateHistoryId || templateHistoryId === "") &&
      (!pageUrl || pageUrl === "")
    )
      updateCompany(
        company._id,
        {
          ...newCompany,
          Home: newHome,
        },
        user.token
      )
        .then(() => {
          getCompany(company._id, dispatch);
          enqueueSnackbar(
            Notification({
              text: "Guardado correctamente",
              variant: "success",
              withDetails: false,
            })
          );
          dispatch(setUpdated(false));
          dispatch(loadingOff());
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(
            Notification({
              text: "Hubo un error al guardar.",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
    else if (templateHistoryId && templateHistoryId !== "") {
      await updateTemplateHistory({
        home: newHome,
        _id: templateHistoryId,
      })
        .then(async () => {
          await getData();
          enqueueSnackbar(
            Notification({
              text: "Guardado correctamente",
              variant: "success",
              withDetails: false,
            })
          );
          dispatch(setUpdated(false));
          dispatch(loadingOff());
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(
            Notification({
              text: "Hubo un error al guardar.",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
    } else if (pageUrl && pageUrl !== "") {
      await updateDynamicPage({
        ...dynamicPage,
        components: newHome,
      })
        .then(() => {
          enqueueSnackbar(
            Notification({
              text: "Guardado correctamente",
              variant: "success",
              withDetails: false,
            })
          );
          dispatch(setUpdated(false));
          dispatch(loadingOff());
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(
            Notification({
              text: "Hubo un error al guardar.",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
    }
  };

  const handleCancel = async () => {
    dispatch(loadingOn());
    await getCompany(company._id, dispatch);
    await getData();
    enqueueSnackbar(
      Notification({
        text: "Guardado correctamente",
        variant: "success",
        withDetails: false,
      })
    );
    dispatch(setUpdated(false));
    dispatch(loadingOff());
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (notFound) {
    return (
      <div className={styles.root}>
        <main className={styles.content}>
          <Typography component="h3" className={styles.NotFoundText}>
            La tienda a la cual desea acceder, no existe, por favor revise la
            url.
          </Typography>
        </main>
      </div>
    );
  } else
    return (
      // <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
      <MuiThemeProvider theme={theme}>
        <audio id="audio" src={AudioMp3} autoPlay muted></audio>
        <div className={styles.root}>
          <CssBaseline />
          {location.pathname !== routes.LOGIN.path &&
            location.pathname !== routes.RESETPASSWORD.path &&
            location.pathname !== routes.MODULE_WEB.path &&
            location.pathname !== routes.MODULE_DYNAMIC_PAGE.path &&
            !(
              location.pathname.includes(routes.WEB.path) &&
              (c === webConstants.routes.BANNERS ||
                c === webConstants.routes.NEWSLETTER ||
                c === webConstants.routes.BODY ||
                c === webConstants.routes.COLORS ||
                c === webConstants.routes.CUSTOMER_SERVICE ||
                c === webConstants.routes.FOOTER ||
                c === webConstants.routes.HEADER ||
                c === webConstants.routes.LOGO ||
                c === webConstants.routes.PRODUCT_CARD ||
                c === webConstants.routes.OTHER_COMPONENTS ||
                c === webConstants.routes.MODAL_PRODUCT_CARD ||
                c === webConstants.routes.TYPOGRAPHY)
            ) && (
              <div>
                <div className={styles.drawerResponsiveLg}>
                  <Drawer
                    variant="permanent"
                    classes={{
                      paper: classNames(
                        styles.drawerPaper,
                        !openDrawer ? styles.drawerPaperClose : ""
                      ),
                    }}
                    open={openDrawer}
                  >
                    <div className={styles.toolbarIcon}>
                      <img
                        src="https://dgdvd9d8e5bk8.cloudfront.net/logonubefoodwhitesvg-svg.svg"
                        alt="Lumarketo"
                      />
                    </div>
                    <Divider />
                    <div
                      style={{
                        overflowY: "auto",
                      }}
                    >
                      {user && <ChileModulesList />}
                    </div>
                  </Drawer>
                </div>
                <div className={styles.drawerResponsive_Md_Sm}>
                  <Drawer
                    variant="temporary"
                    classes={{
                      paper: classNames(styles.drawerPaper),
                    }}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                  >
                    <div className={styles.toolbarIcon}>
                      <img
                        src="https://dgdvd9d8e5bk8.cloudfront.net/logonubefoodwhitesvg-svg.svg"
                        alt="Lumarketo"
                      />
                    </div>
                    <Divider />
                    <div
                      style={{
                        overflowY: "auto",
                      }}
                    >
                      {user && <ChileModulesList />}
                    </div>
                  </Drawer>
                </div>
              </div>
            )}

          {(location.pathname === routes.MODULE_WEB.path ||
            location.pathname === routes.MODULE_DYNAMIC_PAGE.path ||
            (location.pathname.includes(routes.WEB.path) &&
              (c === webConstants.routes.BANNERS ||
                c === webConstants.routes.NEWSLETTER ||
                c === webConstants.routes.BODY ||
                c === webConstants.routes.COLORS ||
                c === webConstants.routes.CUSTOMER_SERVICE ||
                c === webConstants.routes.FOOTER ||
                c === webConstants.routes.HEADER ||
                c === webConstants.routes.LOGO ||
                c === webConstants.routes.PRODUCT_CARD ||
                c === webConstants.routes.OTHER_COMPONENTS ||
                c === webConstants.routes.MODAL_PRODUCT_CARD ||
                c === webConstants.routes.TYPOGRAPHY))) && <ModuleWebSideBar />}

          <main
            className={classNames(
              styles.content,
              location.pathname.includes(webConstants.routes.HOME) ||
                location.pathname.includes(webConstants.routes.LOGIN) ||
                location.pathname.includes(
                  webConstants.routes.RESET_PASSWORD
                ) ||
                location.pathname.includes(webConstants.routes.MODULE_WEB) ||
                location.pathname.includes(
                  webConstants.routes.MODULE_DYNAMIC_PAGE
                )
                ? styles.notPaddingContent
                : ""
            )}
            style={{
              height:
                location.pathname.includes(webConstants.routes.LOGIN) ||
                location.pathname.includes(webConstants.routes.RESET_PASSWORD)
                  ? "100vh"
                  : location.pathname.includes(
                      webConstants.routes.MODULE_WEB
                    ) ||
                    location.pathname.includes(
                      webConstants.routes.MODULE_DYNAMIC_PAGE
                    )
                  ? "100vh"
                  : "100vh",
              marginTop: 0,
              paddingTop: 100,
              paddingBottom: 50,
              backgroundColor: location.pathname.includes(
                `${Routes.DEPOSIT.path}/crear`
              )
                ? "#fafafa"
                : location.pathname.includes(Routes.INTEGRATIONS_NATIVE.path) ||
                  location.pathname.includes(Routes.DEPOSIT.path)
                ? "#f4f5f7"
                : "#fff",
            }}
          >
            {location.pathname !== routes.LOGIN.path &&
              location.pathname !== routes.RESETPASSWORD.path && (
                <AppBar
                  id="header"
                  elevation={0}
                  position="absolute"
                  className={classNames(
                    styles.appBar,
                    location.pathname === routes.MODULE_WEB.path ||
                      location.pathname === routes.MODULE_DYNAMIC_PAGE.path ||
                      (location.pathname.includes(routes.WEB.path) &&
                        (c === webConstants.routes.BANNERS ||
                          c === webConstants.routes.NEWSLETTER ||
                          c === webConstants.routes.BODY ||
                          c === webConstants.routes.COLORS ||
                          c === webConstants.routes.CUSTOMER_SERVICE ||
                          c === webConstants.routes.FOOTER ||
                          c === webConstants.routes.HEADER ||
                          c === webConstants.routes.LOGO ||
                          c === webConstants.routes.PRODUCT_CARD ||
                          c === webConstants.routes.OTHER_COMPONENTS ||
                          c === webConstants.routes.MODAL_PRODUCT_CARD ||
                          c === webConstants.routes.TYPOGRAPHY))
                      ? ""
                      : openDrawer
                      ? styles.appBarShift
                      : ""
                  )}
                >
                  {loadingUI && (
                    <div className={styles.progressContainer}>
                      <LinearProgress
                        variant="query"
                        style={{
                          backgroundColor: "#ff7500",
                        }}
                      />
                    </div>
                  )}
                  <PaymentUpperHeader />
                  {location.pathname === routes.MODULE_WEB.path ||
                  location.pathname === routes.MODULE_DYNAMIC_PAGE.path ||
                  (location.pathname.includes(routes.WEB.path) &&
                    (c === webConstants.routes.BANNERS ||
                      c === webConstants.routes.NEWSLETTER ||
                      c === webConstants.routes.BODY ||
                      c === webConstants.routes.COLORS ||
                      c === webConstants.routes.CUSTOMER_SERVICE ||
                      c === webConstants.routes.FOOTER ||
                      c === webConstants.routes.HEADER ||
                      c === webConstants.routes.LOGO ||
                      c === webConstants.routes.PRODUCT_CARD ||
                      c === webConstants.routes.OTHER_COMPONENTS ||
                      c === webConstants.routes.MODAL_PRODUCT_CARD ||
                      c === webConstants.routes.TYPOGRAPHY)) ? (
                    <Fragment>
                      <ModuleWebToolbar />
                      {(location.pathname === routes.MODULE_DYNAMIC_PAGE.path ||
                        location.pathname === routes.MODULE_WEB.path) && (
                        <InfoToolbar />
                      )}
                    </Fragment>
                  ) : (
                    <Toolbar
                      disableGutters={!openDrawer}
                      className={
                        location.pathname === routes.MODULE_WEB.path ||
                        location.pathname === routes.MODULE_DYNAMIC_PAGE.path ||
                        (location.pathname.includes(routes.WEB.path) &&
                          (c === webConstants.routes.BANNERS ||
                            c === webConstants.routes.NEWSLETTER ||
                            c === webConstants.routes.BODY ||
                            c === webConstants.routes.COLORS ||
                            c === webConstants.routes.CUSTOMER_SERVICE ||
                            c === webConstants.routes.FOOTER ||
                            c === webConstants.routes.HEADER ||
                            c === webConstants.routes.LOGO ||
                            c === webConstants.routes.PRODUCT_CARD ||
                            c === webConstants.routes.OTHER_COMPONENTS ||
                            c === webConstants.routes.MODAL_PRODUCT_CARD ||
                            c === webConstants.routes.TYPOGRAPHY))
                          ? ""
                          : classNames(
                              styles.toolbar,
                              openDrawer ? styles.appBarShift : ""
                            )
                      }
                    >
                      <div className={styles.drawerResponsiveLg}>
                        {!openDrawer ? (
                          <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={() => handleDrawerOpen()}
                            className={classNames(
                              styles.menuButton,
                              openDrawer ? styles.menuButtonHidden : ""
                            )}
                          >
                            <MenuIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            color="inherit"
                            aria-label="Close drawer"
                            onClick={() => handleDrawerClose()}
                            className={classNames(
                              styles.menuButton,
                              !openDrawer ? styles.menuButtonHidden : ""
                            )}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                      </div>
                      <div className={styles.drawerResponsive_Md_Sm}>
                        <IconButton
                          color="inherit"
                          aria-label="Open drawer"
                          onClick={handleDrawerToggle}
                          className={classNames(styles.menuButton)}
                        >
                          <MenuIcon />
                        </IconButton>
                      </div>

                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={styles.title}
                      />
                      {/* <RequestsLogin user={user} /> */}
                      <div className={styles.subsidiaryContainer}>
                        <h6
                          className={styles.Subsidiary}
                          style={{ margin: "0 5px" }}
                        >
                          Sucursal:
                        </h6>
                        <SelectComponent
                          style={{
                            color: "#000",
                            margin: "0px 5px",
                            background: "white",
                            maxwidth: 400,
                            width: "auto",
                          }}
                          name="sucursal"
                          value={subsidiary}
                          array={(user && user.subsidiaries
                            ? subsidiaries
                                .filter((s) =>
                                  user.subsidiaries.find((u) => u === s._id)
                                )
                                .concat(
                                  user.subsidiaries.find(
                                    (item) => item === "all"
                                  )
                                    ? [
                                        {
                                          Name: "Todas las sucursales",
                                          _id: "all",
                                        },
                                      ]
                                    : []
                                )
                            : subsidiaries.concat([
                                { Name: "Todas las sucursales", _id: "all" },
                              ])
                          ).map((item) => {
                            return { name: item.Name, value: item._id };
                          })}
                          handler={(value) => {
                            dispatch(setSubsidiary(value));
                          }}
                        />
                      </div>

                      {/* <RequestsLogin user={user} /> */}
                      <Link
                        href={company.WebLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Button
                          startIcon={<StoreIcon />}
                          className={classnames(
                            styles.NavigateToShop,
                            "ExportButton"
                          )}
                        >
                          Ir a mi tienda
                        </Button>
                      </Link>
                      <WebSocketDemo />
                      {/* <NotificationComponent /> */}
                      <UserHeaderComponent />
                    </Toolbar>
                  )}
                </AppBar>
              )}
            <React.Fragment> {children} </React.Fragment>
            {updated &&
              (location.pathname.includes(webConstants.routes.MODULE_WEB) ||
                location.pathname.includes(
                  webConstants.routes.MODULE_DYNAMIC_PAGE
                )) && (
                <div className={styles.footerGridContainer}>
                  <div>
                    <p className={styles.footerInformation}>
                      No olvides guardas tus cambios y espera alrededor de unos
                      5 minutos para verse reflejado en tu página web.
                    </p>
                  </div>
                  <div>
                    <button
                      // disabled={loading}
                      onClick={() => handleCancel()}
                      className={classNames("btn", styles.footerCancelButton)}
                    >
                      <h6 className={styles.footerCancelButtonText}>
                        Cancelar
                      </h6>
                    </button>
                    <button
                      // disabled={loading}
                      onClick={() => handleHomeSave()}
                      className={classNames("btn", styles.footerSaveButton)}
                    >
                      <h6 className={styles.footerSaveButtonText}>Guardar</h6>
                    </button>
                  </div>
                </div>
              )}
          </main>
        </div>
      </MuiThemeProvider>
    );
}

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Montserrat-Light !important",
  },
  palette: {
    primary: {
      main: "#122526",
    },
  },
  overrides: {
    MuiIcon: {
      root: {
        height: "auto",
        width: 25,
        margin: "auto",
      },
    },
    MuiLink: {
      root: {
        marginLeft: "8%",
        color: "#000",
        margin: 0,
        padding: 0,
        display: "contents",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#122526",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#122526",
        // opacity: 0.53, // 'rgb(181, 68, 63)'
        // backgroundImage:
        //   'linear-gradient(-45deg, rgb(255, 218, 0) 10%, rgba(255, 0, 0, 0))'
      },
    },
    MuiOutlinedInput: {
      root: {
        height: "auto",
        margin: "5px 0",
      },
      input: {
        padding: "5.5px 14px",
      },
      notchedOutline: {
        border: "0.1px solid gray",
        borderColor: "gray !important",
        borderRadius: 0,
      },
      // "$&focused": {
      //   border: "2px solid #122526",
      //   borderColor: "#122526",
      // },
      adornedEnd: {
        padding: "0 !important",
      },
    },
    MuiTextField: {
      root: {
        height: "auto",
        margin: "5px 0px",
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 15,
        color: "#7A7A7A",
      },
    },
    MuiFormControl: {
      root: {
        margin: "5px",
      },
    },
    MuiFormGroup: {
      root: {
        flexDirection: "column !important",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: "5%",
        marginLeft: "5%",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#122526 !important",
      },
    },
    MuiCardHeader: {
      root: {
        width: 250,
      },
    },
    MuiBadge: {
      colorPrimary: {
        color: "white",
        backgroundColor: "#122526",
      },
    },
    MuiDrawer: {
      docked: {
        height: "100vh",
      },
    },
    MuiListItemIcon: {
      root: {
        marginRight: 0,
        minWidth: 16,
      },
    },
    MuiTypography: {
      body1: {
        color: "white",
        fontSize: 12,
        fontFamily: "Montserrat-Light !important",
        fontWeight: 700,
        lineHeight: 1.43,
      },
      body2: {
        color: "white",
        fontSize: 12,
        fontFamily: "Montserrat-Light !important",
        fontWeight: 700,
        lineHeight: 1.43,
      },
    },
    MuiChip: {
      root: {
        color: "#fff",
        backgroundColor: "rgb(35, 47, 62)",
      },
      deleteIcon: {
        color: "#fff !important",
      },
      deleteIconColorPrimary: {
        color: "#fff",
      },
    },
    MuiInput: {
      root: {
        border: "none",
      },
    },
    MuiListItemText: {
      root: {
        padding: "0 5px",
      },
    },
    MuiListItem: {
      root: {
        height: 25,
        "&:hover, &:focus": {
          background: "rgba(241,222,250,0.275) !important",
          "&:before": {
            background: "#fff",
            transform: "scale(1)",
          },
        },
        "&$selected": {
          "&.Mui-selected": {
            background: "initial",
            "&:hover, &:focus": {
              background: "rgba(241,222,250,0.275) !important",
              color: "#663399",
              "&:before": {
                background: "#fff !important",
                transform: "scale(1)",
              },
            },
            "&:after": {
              width: "calc(0.5rem + 8px)",
              background: "rgb(138, 75, 175) !important",
              transform: "translateX(0)",
            },
          },
        },
      },
      dense: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
});

export default App;
