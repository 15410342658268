import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import service from "../utils/services/company";

export const companySlice = createSlice({
  name: "company",
  initialState: initialState.company,
  reducers: {
    receiveCollections: (state, action) => {
      state.SKUCollections.splice(0, state.SKUCollections.length);
      state.SKUCollections.push(...action.payload);
    },
    receiveHome: (state, action) => {
      state.Home.splice(0, state.Home.length);
      state.Home.push(...action.payload);
    },
    receiveSlides: (state, action) => {
      state.Slides.splice(0, state.Slides.length);
      state.Slides.push(...action.payload);
    },
    receiveCategories: (state, action) => {
      state.Categories.splice(0, state.Categories.length);
      state.Categories.push(...action.payload);
    },
    receiveBrands: (state, action) => {
      state.Brands.splice(0, state.Brands.length);
      state.Brands.push(...action.payload);
    },
    receivePayments: (state, action) => {
      state.Payments.splice(0, state.Payments.length);
      state.Payments.push(...action.payload);
    },
    receivePayment: (state, action) => {
      const index = state.Payments.findIndex(
        (payment) => payment._id === action.payload._id
      );
      if (index !== -1) {
        state.Payments[index] = action.payload;
      } else {
        state.Payments.push(action.payload);
      }
    },
    receiveBrand: (state, action) => {
      const index = state.Brands.findIndex(
        (brand) => brand._id === action.payload._id
      );
      if (index !== -1) {
        state.Brands[index] = action.payload;
      } else {
        state.Brands.push(action.payload);
      }
    },
    deleteBrand: (state, action) => {
      const index = state.Brands.findIndex(
        (brand) => brand._id === action.payload._id
      );
      state.Brands.splice(index, 1);
    },
    receiveCategory: (state, action) => {
      const index = state.Categories.findIndex(
        (category) => category._id === action.payload._id
      );
      if (index !== -1) {
        state.Categories[index] = action.payload;
      } else {
        state.Categories.push(action.payload);
      }
    },
    receiveSkuNumber: (state, action) => {
      state.SKUNumber = action.payload;
    },
    receiveCompany: (state, action) => {
      state.firstScheduledTimeSlot = action.payload.firstScheduledTimeSlot;
      state.useRightMiniCart = action.payload.useRightMiniCart;
      state.useWithdrawalEmailScheduling =
        !!action.payload.useWithdrawalEmailScheduling;
      state.useDeliveryPin = action.payload.useDeliveryPin ?? false;
      state.useCheckoutInvoiceModal =
        action.payload.useCheckoutInvoiceModal ?? false;
      state.useSubsidiaryPayment = action.payload.useSubsidiaryPayment;
      state.useMultiStore = action.payload.useMultiStore;
      state.loyaltyPointsComponent = action.payload.loyaltyPointsComponent;
      state.loyaltyName = action.payload.loyaltyName || "";
      state.useLoyaltyPoints = action.payload.useLoyaltyPoints ?? false;
      state.Integrations = action.payload.Integrations;
      state.UseShippingScheduling = action.payload.UseShippingScheduling;
      state.Payments = action.payload.Payments;
      state.UseNubefoodFooter = action.payload.UseNubefoodFooter;
      state.UseDeliveryModal = action.payload.UseDeliveryModal;
      state.UseAutoOrderAcceptance = action.payload.UseAutoOrderAcceptance;
      state.UseRetryShipping = action.payload.UseRetryShipping;
      state.FacebookDomainVerification =
        action.payload.FacebookDomainVerification;
      state.UseShippingPreference = action.payload.UseShippingPreference;
      state.ShippingPreference = action.payload.ShippingPreference;
      state.AutoLogout = action.payload.AutoLogout;
      state.TicketType = action.payload.TicketType;
      state.HTMLSlidePaths = action.payload.HTMLSlidePaths;
      state.HTMLSlideProps = action.payload.HTMLSlideProps;
      state.UseHTMLSlide = action.payload.UseHTMLSlide;
      state.HTMLSlide = action.payload.HTMLSlide;
      state.AddressInfo = action.payload.AddressInfo;
      state.City = action.payload.City;
      state.State = action.payload.State;
      state.UseMultiResolution = action.payload.UseMultiResolution;
      state.UseMultiStock = action.payload.UseMultiStock;
      state.Whatsapp = action.payload.Whatsapp;
      state.UseTiendaHouse = action.payload.UseTiendaHouse;
      state.BalanceDays = action.payload.BalanceDays;
      state.FacebookPixelId = action.payload.FacebookPixelId;
      state.UseGridPLP = action.payload.UseGridPLP;
      state.Quotes = action.payload.Quotes;
      state.Address = action.payload.Address;
      state.WebLink = action.payload.WebLink;
      state.Country = action.payload.Country;
      state.DesktopLogo = action.payload.DesktopLogo;
      state.Email = action.payload.Email;
      state.EmailConfig = action.payload.EmailConfig;
      state.FreeShipping = action.payload.FreeShipping;
      state.Lat = action.payload.Lat;
      state.Lng = action.payload.Lng;
      state.Logo = action.payload.Logo;
      state.MobileLogo = action.payload.MobileLogo;
      state.WhiteMobileLogo = action.payload.WhiteMobileLogo;
      state.WhiteDesktopLogo = action.payload.WhiteDesktopLogo;
      state.LoadingLogo = action.payload.LoadingLogo;
      state.MiniCart = action.payload.MiniCart;
      state.SubHeader = action.payload.SubHeader;
      state.Name = action.payload.Name;
      state.NavBar.splice(0, state.NavBar.length);
      state.NavBar.push(...action.payload.NavBar);
      state.ProductTabs.splice(0, state.ProductTabs.length);
      state.ProductTabs.push(...action.payload.ProductTabs);
      state.Home.splice(0, state.Home.length);
      state.Home.push(...action.payload.Home);
      state.Slides.splice(0, state.Slides.length);
      state.Slides.push(...action.payload.Slides);
      state.SEO = action.payload.SEO;
      state.SKUNumber = action.payload.SKUNumber;
      state.Services.Durations.splice(0, state.Services.Durations.length);
      state.Services.Durations.push(...action.payload.Services.Durations);
      state.Services.Modalities.splice(0, state.Services.Modalities.length);
      state.Services.Modalities.push(...action.payload.Services.Modalities);
      state.ShippingCost = action.payload.ShippingCost;
      state.SocialNetworks.splice(0, state.SocialNetworks.length);
      state.SocialNetworks.push(...action.payload.SocialNetworks);
      state._id = action.payload._id;
      state.UseChildrenForProducts = action.payload.UseChildrenForProducts;
      state.UseInfiniteScroll = action.payload.UseInfiniteScroll;
      state.Colors.splice(0, state.Colors.length);
      state.Colors.push(...action.payload.Colors);
      state.Tlf = action.payload.Tlf;
      state.UseNewsletterPopup = action.payload.UseNewsletterPopup;
      state.UsePayments = {
        ...action.payload.UsePayments,
      };
      state.CustomerService = {
        ...action.payload.CustomerService,
      };
      state.SKUCollections.splice(0, state.SKUCollections.length);
      state.SKUCollections.push(...action.payload.SKUCollections);
      state.Header = { ...action.payload.Header };
      state.Body = { ...action.payload.Body };
      state.Footer = { ...action.payload.Footer };
      state.Newsletter = { ...action.payload.Newsletter };
      state.Typography = { ...action.payload.Typography };
      state.GoogleAnalytics = { ...action.payload.GoogleAnalytics };
      state.FavIcon = action.payload.FavIcon;
      state.UseBrandSlider = action.payload.UseBrandSlider;
      state.BsaleToken = action.payload.BsaleToken;
      state.UseShippingPolicy = action.payload.UseShippingPolicy;
      state.UseSubsidiary = action.payload.UseSubsidiary;
      state.GlobalConfigHome = action.payload.GlobalConfigHome;
      state.TemplateHistory = action.payload.TemplateHistory;
      state.HomeComponentHistory = action.payload.HomeComponentHistory;
      state.TemplateId = action.payload.TemplateId;
      state.ProductCard = action.payload.ProductCard;
      state.UseExplanatoryHand = action.payload.UseExplanatoryHand;
      state.UseEmailConfig = action.payload.UseEmailConfig;
      state.UseHome = action.payload.UseHome;
      state.PrintOrder = action.payload.PrintOrder;
      state.InfinityOrderAlert = action.payload.InfinityOrderAlert;
      state.UseBackgroundImage = action.payload.UseBackgroundImage;
      state.Title = action.payload.Title;
      state.UseQrSurvey = action.payload.UseQrSurvey;
      state.UseStreetNumberValidation =
        action.payload.UseStreetNumberValidation;
      state.ModalProductCard = action.payload.ModalProductCard;
    },
    clearData: (state) => {
      state.useDeliveryPin = initialState.useDeliveryPin;
      state.useCheckoutInvoiceModal = initialState.useCheckoutInvoiceModal;
      state.useMultiStore = initialState.useMultiStore;
      state.UseRetryShipping = initialState.company.UseRetryShipping;
      state.UseShippingPreference = initialState.company.UseShippingPreference;
      state.ShippingPreference = initialState.company.ShippingPreference;
      state.ModalProductCard = initialState.company.ModalProductCard;
      state.UseQrSurvey = initialState.company.UseQrSurvey;
      state.FacebookDomainVerification =
        initialState.company.FacebookDomainVerification;
      state.AutoLogout = initialState.company.AutoLogout;
      state.TicketType = initialState.company.TicketType;
      state.HTMLSlidePaths = initialState.company.HTMLSlidePaths;
      state.HTMLSlideProps = initialState.company.HTMLSlideProps;
      state.UseHTMLSlide = initialState.company.UseHTMLSlide;
      state.HTMLSlide = initialState.company.HTMLSlide;
      state.Title = initialState.company.Title;
      state.UseBackgroundImage = initialState.company.UseBackgroundImage;
      state.InfinityOrderAlert = initialState.company.InfinityOrderAlert;
      state.PrintOrder = initialState.company.PrintOrder;
      state.AddressInfo = undefined;
      state.UseMultiStock = initialState.company.UseMultiStock;
      state.UseEmailConfig = initialState.company.UseEmailConfig;
      state.UseShippingPolicy = initialState.company.UseShippingPolicy;
      state.UseGridPLP = initialState.company.UseGridPLP;
      state.Quotes = initialState.company.Quotes;
      state.Address = initialState.company.Address;
      state.WebLink = initialState.company.WebLink;
      state.Country = initialState.company.Country;
      state.DesktopLogo = initialState.company.DesktopLogo;
      state.Email = initialState.company.Email;
      state.EmailConfig = initialState.company.EmailConfig;
      state.FreeShipping = initialState.company.FreeShipping;
      state.Lat = initialState.company.Lat;
      state.Lng = initialState.company.Lng;
      state.FavIcon = initialState.company.FavIcon;
      state.Logo = initialState.company.Logo;
      state.MobileLogo = initialState.company.MobileLogo;
      state.WhiteMobileLogo = initialState.company.WhiteMobileLogo;
      state.WhiteDesktopLogo = initialState.company.WhiteDesktopLogo;
      state.LoadingLogo = initialState.company.LoadingLogo;
      state.Name = initialState.company.Name;
      state.NavBar.splice(0, state.Name.length);
      state.ProductTabs.splice(0, state.ProductTabs.length);
      state.SEO = initialState.company.SEO;
      state.SKUNumber = initialState.company.SKUNumber;
      state.Services = initialState.company.Services;
      state.ShippingCost = initialState.company.ShippingCost;
      state.SocialNetworks.splice(0, state.SocialNetworks.length);
      state._id = initialState.company._id;
      state.UseChildrenForProducts = false;
      state.UseInfiniteScroll = false;
      state.Colors.splice(0, state.Colors.length);
      state.UseNewsletterPopup = false;
      state.UsePayments = {
        useBolivar: false,
        useZelle: false,
        usePaypal: false,
        useFlowCL: false,
        useMercadopago: false,
      };
      state.CustomerService = {
        dialog: {
          desktop: {
            img: "",
            resolution: "",
          },
          tablet: {
            img: "",
            resolution: "",
          },
          mobile: {
            img: "",
            resolution: "",
          },
        },
      };
      state.SKUCollections.splice(0, state.SKUCollections.length);
      state.Home.splice(0, state.Home.length);
      state.TemplateHistory.splice(0, state.TemplateHistory.length);
      state.HomeComponentHistory.splice(0, state.HomeComponentHistory.length);
      state.TemplateId = "";
      state.Header = initialState.company.Header;
      state.Footer = initialState.company.Footer;
      state.Body = initialState.company.Body;
      state.Newsletter = initialState.company.Newsletter;
      state.Typography = initialState.company.Typography;
      state.GoogleAnalytics = initialState.company.GoogleAnalytics;
      state.UseBrandSlider = initialState.company.UseBrandSlider;
      state.BsaleToken = initialState.company.BsaleToken;
      state.UseSubsidiary = initialState.company.UseSubsidiary;
      state.UseHome = initialState.company.UseHome;
      state.UseStreetNumberValidation =
        initialState.company.UseStreetNumberValidation;
    },
  },
});

export const {
  receiveBrand,
  receiveBrands,
  receiveCategories,
  receiveCategory,
  receiveCompany,
  receiveHome,
  receivePayment,
  receivePayments,
  receiveSlides,
  receiveCollections,
  clearData,
  deleteBrand,
  receiveSkuNumber,
} = companySlice.actions;

export const getCompany = async (idCompany, dispatch) => {
  await service.getByIdCompany(idCompany).then((res) => {
    dispatch(receiveCompany(res));
    return res;
  });
};

export const getSkuNumber = async (idCompany, token, dispatch) => {
  await service.getSKUNumber(idCompany, token).then((res) => {
    dispatch(receiveSkuNumber(res.skuNumber));
    return res.skuNumber;
  });
};

export const update = async (data, token) => await service.update(data, token);

export const selectCompany = (state) => state.company;
export const selectSEO = (state) => state.company.SEO;
export const selectBrands = (state) => state.company.Brands;
export const selectCategories = (state) => state.company.Categories;
export const selectSlides = (state) => state.company.Slides;
export const selectHome = (state) => state.company.Home;
export const selectSKUCollections = (state) => state.company.SKUCollections;

export default companySlice.reducer;
