import { lazy, Suspense } from "react";
import LoadingPage from "../containers/loading";

const Page = lazy(() => import("../components/marketing/integrations/webhook"));

export default function WebhookRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Page />
    </Suspense>
  );
}
