/* eslint-disable react-hooks/exhaustive-deps */
import {
  createTheme,
  MuiThemeProvider,
  TextField as MuiTextField,
  withStyles,
} from "@material-ui/core";
import { deburr } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import uuid from "react-uuid";
import { Notification } from "../../containers/notification";
//import { selectProducts } from "../../reducers/productSlice";
import { selectLoadingUI } from "../../reducers/uiSlice";
import MultiAutocompleteComponent from "../selects/multiAutocomplete.component";
import useGetProducts from "../../utils/hooks/products/useGetProducts";

export default function InputComponent({
  validation,
  value,
  placeholder,
  handler,
  disabled,
  helperText = "",
  style = {},
  type = "text",
  inputProps = {},
  error = false,
  multiline = false,
  rows = 1,
  ref = undefined,
  useBlueBackground = false,
  onMouseLeave = () => null,
  width,
}) {
  const [updated, setUpdated] = useState(false);
  const loading = useSelector(selectLoadingUI);

  return (
    <MuiThemeProvider theme={useBlueBackground ? blueBackgroundTheme : theme}>
      <TextField
        ref={ref}
        style={{ ...style, width: width ?? "100%" }}
        error={validation && updated}
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          handler(e);
          setUpdated(true);
        }}
        helperText={validation && updated ? helperText : ""}
        disabled={disabled || loading}
        className="inputText"
        type={type}
        InputProps={inputProps}
        multiline={multiline}
        rows={rows}
        onMouseLeave={onMouseLeave}
      />
    </MuiThemeProvider>
  );
}

export function StableStateInputComponent({
  validation,
  value,
  placeholder,
  handler,
  disabled,
  helperText = "",
  style = {},
  type = "text",
  inputProps = {},
  error = false,
  multiline = false,
  rows = 1,
  ref = undefined,
  useBlueBackground = false,
  onMouseLeave = () => null,
  width,
  time = 200,
}) {
  const [updated, setUpdated] = useState(false);
  const loading = useSelector(selectLoadingUI);
  const [text, setText] = useState("");

  useEffect(() => {
    if (value !== "" && value !== text) {
      setText(value);
    }
  }, [value]);

  useEffect(() => {
    let timer1 = setTimeout(() => handlerChange(), time);

    return () => {
      clearTimeout(timer1);
    };
  }, [text]);

  const handlerChange = () => {
    if (value !== text) handler(text);
  };

  return (
    <MuiThemeProvider theme={useBlueBackground ? blueBackgroundTheme : theme}>
      <TextField
        ref={ref}
        style={{ ...style, width: width ?? "100%" }}
        error={validation && updated}
        variant="outlined"
        placeholder={placeholder}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
          setUpdated(true);
        }}
        helperText={validation && updated ? helperText : ""}
        disabled={disabled || loading}
        className="inputText"
        type={type}
        InputProps={inputProps}
        multiline={multiline}
        rows={rows}
        onMouseLeave={onMouseLeave}
      />
    </MuiThemeProvider>
  );
}

export function SelectComponent({
  array = [],
  value,
  handler,
  disabled,
  style,
  returnValue = true,
  width = "100%",
  useBlueBackground = false,
  placeholder = "Seleccionar...",
}) {
  return (
    <MuiThemeProvider theme={useBlueBackground ? blueBackgroundTheme : theme}>
      <select
        disabled={disabled}
        style={{
          width,
          minHeight: 35,
          backgroundColor: "transparent",
          border: "0.1px solid gray",
          borderColor: "gray !important",
          borderRadius: 0,
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 15,
          color: "#4c4c4c",
          ...style,
        }}
        variant="outlined"
        value={value}
        onChange={(e) => handler(returnValue ? e.target.value : e)}
      >
        <option key={uuid()} value={""} disabled style={{ color: "#000" }}>
          {placeholder}
        </option>
        {array &&
          array.length > 0 &&
          array.map((item) => (
            <option
              key={uuid()}
              value={item.value}
              // onClick={() => handler(item.value)}
              style={{ color: "#000" }}
            >
              {item.name}
            </option>
          ))}
      </select>
    </MuiThemeProvider>
  );
}

export const MultiSKUInputComponent = ({
  classes,
  skus,
  handleChange,
  title = "SKUs",
  maxQty = 4,
  useBlueBackground = false,
  onMouseLeave = () => null,
  width = "100%",
  company,
  user,
}) => {
  //const products = useSelector(selectProducts);
  const [selected, setSelect] = useState([]);
  const [prevSelected, setPrevSelect] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [autocompleteInputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const { products } = useGetProducts(
    company,
    user?.token /*  [
    refreshKey,
  ] */
  );

  useEffect(() => {
    if (selected.length === 0 && prevSelected.length === 0) {
      setSelect(
        skus
          .map((sku) => {
            return suggestions.find((s) => {
              return s.value === sku;
            });
          })
          .filter((item) => item && item.label && item.value)
      );
      setPrevSelect(
        skus
          .map((sku) => suggestions.find((s) => s.value === sku))
          .filter((item) => item && item.label && item.value)
      );
    } else if (skus.length === 0 && selected.length > 0) setSelect([]);
  }, [skus, suggestions]);

  useEffect(() => {
    function getSuggestionsProducts() {
      let suggestions = [];
      if (products !== undefined) {
        products.forEach((Product) => {
          if (
            suggestions.find((item) => item.label === Product.Name) ===
              undefined &&
            Product.Show &&
            Product.Children &&
            Product.Children[0] &&
            Product.Children[0].Stocks.find((item) => item.qty > 0)
          ) {
            suggestions.push({
              label: Product.Name,
              value:
                Product.Children[0] && Product.Children[0].SKU
                  ? Product.Children[0].SKU
                  : "",
            });
            Product.Children.forEach((child) => {
              suggestions.push({
                label: child.SKU,
                value: child && child.SKU ? child.SKU : "",
              });
            });
          }
        });
        setSuggestions(suggestions.filter((item) => item && item.label));
      }
    }
    getSuggestionsProducts();
  }, [products, skus]);

  useEffect(() => {
    const jsonSkus = JSON.stringify(skus);
    const selectedSkus = selected.map((item) => item.value);
    const jsonSelected = JSON.stringify(selectedSkus);
    if (jsonSelected !== jsonSkus && updated) {
      handleChange(selectedSkus);
      setPrevSelect(skus);
      setUpdated(false);
    }
  }, [selected, skus]);

  const getSuggestions = (value) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
      ? []
      : suggestions.filter((suggestion) => {
          const keep =
            count < 5 &&
            suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

          if (keep) {
            count += 1;
          }
          return keep;
        });
  };

  const handleKeyDown = (event) => {
    if (
      selected.length &&
      !autocompleteInputValue.length &&
      event.key === "Backspace"
    ) {
      setSelect(selected.slice(0, selected.length - 1));
      setUpdated(true);
    }
  };

  const handleInputChange = (event) => setInputValue(event.target.value);

  const handleChangeAutocomplete = (item) => {
    if (selected.length <= maxQty - 1) {
      var selectedItem = [];
      if (selected.indexOf(item) === -1) {
        selectedItem = [...selected, item];
      }
      setSelect(selectedItem);
      setInputValue("");
      setUpdated(true);
    } else {
      enqueueSnackbar(
        Notification({
          text: "Ha alcanzado el máximo de productos para el componente.",
          variant: "error",
          withDetails: false,
        })
      );
    }
  };
  const handleDelete = (item) => () => {
    var items = [...selected];
    const index = items.findIndex((a) => a.value === item);
    if (index > -1) items.splice(index, 1);
    setSelect(items);
    setUpdated(true);
  };

  return (
    <MuiThemeProvider theme={useBlueBackground ? blueBackgroundTheme : theme}>
      <MultiAutocompleteComponent
        handleInputChange={handleInputChange}
        handleChange={handleChangeAutocomplete}
        handleDelete={handleDelete}
        getSuggestions={getSuggestions}
        selectedItem={selected}
        handleKeyDown={handleKeyDown}
        inputValue={autocompleteInputValue}
        placeholder="Buscar por SKU"
        width={width}
      />
    </MuiThemeProvider>
  );
};

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        minHeight: 35,
      },
      notchedOutline: {
        border: "0.1px solid gray",
        borderColor: "gray !important",
        borderRadius: 0,
      },
      // "$&focused": {
      //   border: "2px solid #122526",
      //   borderColor: "#122526",
      // },
      adornedEnd: {
        padding: "0 !important",
      },
      input: {
        padding: "5px 10px",
      },
    },
    MuiTextField: {
      root: {
        minHeight: 35,
        margin: "0px 0px 5px 0px",
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 15,
        color: "#4c4c4c",
      },
    },
    MuiInput: {
      root: {
        border: "none",
      },
    },
  },
});

const blueBackgroundTheme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        minHeight: 35,
      },
      notchedOutline: {
        border: "0.1px solid gray",
        borderColor: "gray !important",
        borderRadius: 0,
      },
      // "$&focused": {
      //   border: "2px solid #122526",
      //   borderColor: "#122526",
      // },
      adornedEnd: {
        padding: "0 !important",
      },
      input: {
        padding: "5px 10px",
      },
    },
    MuiTextField: {
      root: {
        minHeight: 35,
        margin: "0px 0px 5px 0px",
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 15,
        color: "#fff",
      },
    },
    MuiInput: {
      root: {
        border: "none",
      },
    },
  },
});

const TextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& fieldset": { borderRadius: 5 },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
})(MuiTextField);
