/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCompany } from "../../../../../reducers/companySlice";
import {
  createSimphonyOracle,
  removeSimphonyOracle,
  getSimphonyOracle,
  updateSimphonyOracle,
} from "../../../../../utils/services/company";
import { useDispatch } from "react-redux";
import {
  selectLoadingUI,
  loadingOn,
  loadingOff,
} from "../../../../../reducers/uiSlice";
import { Notification } from "../../../../../containers/notification";
import { useSnackbar } from "notistack";
import { selectUser } from "../../../../../reducers/userSlice";
import {
  selectChangesDeposit,
  setChanges,
} from "../../../../../reducers/depositSlice";
import { createChangeRecord } from "../../../../../reducers/depositChangesSlice";
import dateUtils from "../../../../../utils/date";
import { useNavigate } from "react-router-dom";

// CSS

import styles from "./styles.module.css";

//MATERIAL
import InputComponent from "../../../../inputs";
import Toolbar from "../../../../card/toolbar";
import { Tab, Tabs } from "@material-ui/core";
import { selectSubsidiaries } from "../../../../../reducers/subsidiarySlice";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function SimphonyOracleComponent() {
  const [orgShortName, setOrgShortName] = useState("");
  const [locRef, setLocRef] = useState("");
  const [rcvRef, setRcvRef] = useState("");
  const [employeeRef, setEmployeeRef] = useState("");
  const [orderTypeRef, setOrderTypeRef] = useState("");
  const [tenderId, setTenderId] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [orgName, setOrgName] = useState("");
  const [clientId, setClientId] = useState("");
  const [updated, setUpdated] = useState(false);
  const [availableDelete, setAvailableDelete] = useState(false);
  const [subsidiaryId, setSubsidiaryId] = useState("");
  const subsidiaries = useSelector(selectSubsidiaries);
  const company = useSelector(selectCompany);
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoadingUI);
  const changes = useSelector(selectChangesDeposit);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const oracleIcon = "https://dgdvd9d8e5bk8.cloudfront.net/oraclerounded.png";

  useEffect(() => {
    if (
      subsidiaries &&
      subsidiaries.length > 0 &&
      (!subsidiaryId || subsidiaryId === "")
    )
      setSubsidiaryId(subsidiaries[0]._id);
  }, [subsidiaries]);

  useEffect(() => {
    if (company && company._id !== "" && subsidiaryId && subsidiaryId !== "")
      initialize();
  }, [company, subsidiaryId]);

  const initialize = async () => {
    const found = await getSimphonyOracle(company._id, subsidiaryId).catch(
      (err) => {
        console.log(err);
        return null;
      }
    );

    if (found && found.orgShortName) {
      setOrgShortName(found.orgShortName);
      setLocRef(found.locRef);
      setRcvRef(found.rcvRef);
      setEmployeeRef(found.employeeRef);
      setOrderTypeRef(found.orderTypeRef);
      setTenderId(found.tenderId);
      setClientId(found.clientId);
      setUsername(found.username);
      setPassword(found.password);
      setOrgName(found.orgName);
    } else {
      setOrgShortName("");
      setLocRef("");
      setRcvRef("");
      setEmployeeRef("");
      setOrderTypeRef("");
      setTenderId("");
      setClientId("");
      setUsername("");
      setPassword("");
      setOrgName("");
    }

    setAvailableDelete(!!found);
  };

  const updateChanges = (fieldName, newValue) => {
    const newChanges = [];
    newChanges.push(...changes);
    var index = -1;
    const change = {
      text: "",
      oldValue: null,
      newValue: null,
      date: dateUtils.getUTC().slice(0, 19).replace("T", " "),
    };

    change.newValue = newValue;
    change.oldValue = company && company[fieldName] ? company[fieldName] : "";

    switch (fieldName) {
      case "orgShortName":
        change.text = "Org Short Name";
        break;
      case "locRef":
        change.text = "LocRef";
        break;
      case "rcvRef":
        change.text = "RcvRef";
        break;
      case "employeeRef":
        change.text = "EmployeeRef";
        break;
      case "orderTypeRef":
        change.text = "OrderTypeRef";
        break;
      case "tenderId":
        change.text = "TenderId";
        break;
      case "clientId":
        change.text = "ClientId";
        break;
      case "username":
        change.text = "Username";
        break;
      case "password":
        change.text = "Password";
        break;
      case "orgName":
        change.text = "OrgName";
        break;
      default:
        break;
    }

    index = newChanges.findIndex(
      (item) => item.text.toString() === change.text.toString()
    );

    if (index !== -1) {
      change.oldValue = newChanges[index].oldValue;
      newChanges[index] = change;
    } else {
      newChanges.push(change);
    }
    dispatch(setChanges(newChanges));
  };

  const handleCancel = () => initialize();

  const handleSave = async () => {
    dispatch(loadingOn());
    const found = await getSimphonyOracle(company._id, subsidiaryId).catch(
      (err) => {
        console.log(err);
        return null;
      }
    );
    if (!found || (found && !found.orgShortName)) {
      createSimphonyOracle({
        orgShortName,
        locRef,
        rcvRef,
        employeeRef,
        orderTypeRef,
        tenderId,
        orgName,
        clientId,
        username,
        password,
        companyId: company._id,
        subsidiaryId,
      })
        .then((res) => {
          dispatch(loadingOff());
          enqueueSnackbar(
            Notification({
              text: "Datos de la tienda actualizados",
              variant: "success",
              withDetails: false,
            })
          );
          setUpdated(false);
          createChangeRecord(
            {
              user: user,
              username: user.username,
              fullName: `${user.firstName} ${user.lastName}`,
              idUser: user.id,
              idCompany: company._id,
              idObject: company._id,
              module: "settings",
              submodule: "simphony",
              createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
              changes: changes,
            },
            user.token,
            dispatch
          ).catch((err) => console.log(err));
        })
        .catch((err) => {
          enqueueSnackbar(
            Notification({
              text: "Error al actualizar datos de la tienda",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
    } else if (found && found.orgShortName)
      updateSimphonyOracle({
        orgShortName,
        locRef,
        rcvRef,
        employeeRef,
        orderTypeRef,
        tenderId,
        subsidiaryId,
        orgName,
        clientId,
        username,
        password,
        companyId: company._id,
      })
        .then((res) => {
          dispatch(loadingOff());
          enqueueSnackbar(
            Notification({
              text: "Datos de la tienda actualizados",
              variant: "success",
              withDetails: false,
            })
          );
          setUpdated(false);
          createChangeRecord(
            {
              user: user,
              username: user.username,
              fullName: `${user.firstName} ${user.lastName}`,
              idUser: user.id,
              idCompany: company._id,
              idObject: company._id,
              module: "settings",
              submodule: "simphony",
              createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
              changes: changes,
            },
            user.token,
            dispatch
          ).catch((err) => console.log(err));
        })
        .catch((err) => {
          enqueueSnackbar(
            Notification({
              text: "Error al actualizar datos de la tienda",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
  };

  const handleRemove = async () => {
    dispatch(loadingOn());
    removeSimphonyOracle({ companyId: company._id, subsidiaryId })
      .then(async (res) => {
        dispatch(loadingOff());
        await initialize();
        enqueueSnackbar(
          Notification({
            text: "Datos de la tienda actualizados",
            variant: "success",
            withDetails: false,
          })
        );
        setUpdated(false);
        createChangeRecord(
          {
            user: user,
            username: user.username,
            fullName: `${user.firstName} ${user.lastName}`,
            idUser: user.id,
            idCompany: company._id,
            idObject: company._id,
            module: "settings",
            submodule: "simphony",
            createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
            changes: changes,
          },
          user.token,
          dispatch
        ).catch((err) => console.log(err));
      })
      .catch((err) => {
        enqueueSnackbar(
          Notification({
            text: "Error al actualizar datos de la tienda",
            variant: "error",
            withDetails: false,
          })
        );
        dispatch(loadingOff());
      });
  };
  const handleClose = () => {
    navigate(-1);
  };
  return (
    <div className={styles.parentContainer}>
      <button className={styles.backButton} onClick={() => handleClose()}>
        <span>
          <ArrowBackIcon />
        </span>
        Regresar
      </button>
      <div className={styles.infoContainer}>
        <div className={styles.titleLogoContainer}>
          <Toolbar title="Simphony Gen2 Oracle" />
          <img src={oracleIcon} alt="logo" className={styles.imageElement} />
        </div>
        <div className={styles.infoDataContainer}>
          <p className={styles.textDescription}>
            Con las credenciales de Simphony Gen2 Oracle podrás integrar tu
            sistema administrativo de restaurante en uno solo. Esto significa
            que los pedidos que recibas a través de NubeFood serán recibidos
            automáticamente en tu sistema Oracle, permitiéndote tener un control
            centralizado de todas las ventas diarias, así como también la
            generación de una boleta por cada pedido
          </p>
          {subsidiaries && (
            <div>
              <Tabs
                value={subsidiaryId}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, newValue) => setSubsidiaryId(newValue)}
                aria-label="disabled tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                {subsidiaries.map((item) => (
                  <Tab label={item.Name} value={item._id} />
                ))}
              </Tabs>
            </div>
          )}
          <div className={styles.inputStackContainer}>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Org Name</label>

              <InputComponent
                placeholder="Ej. xxx"
                type="text"
                handler={(e) => {
                  setOrgName(e.target.value);
                  updateChanges("orgName", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={orgName === ""}
                value={orgName}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Username</label>

              <InputComponent
                placeholder="Ej. xxx"
                type="text"
                handler={(e) => {
                  setUsername(e.target.value);
                  updateChanges("username", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={username === ""}
                value={username}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Password</label>

              <InputComponent
                placeholder="Ej. xxx"
                type="text"
                handler={(e) => {
                  setPassword(e.target.value);
                  updateChanges("password", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={password === ""}
                value={password}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Client Id</label>

              <InputComponent
                placeholder="Ej. xxx"
                type="text"
                handler={(e) => {
                  setClientId(e.target.value);
                  updateChanges("clientId", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={clientId === ""}
                value={clientId}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Org Short Name</label>

              <InputComponent
                placeholder="Ej. xxx"
                type="text"
                handler={(e) => {
                  setOrgShortName(e.target.value);
                  updateChanges("orgShortName", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={orgShortName === ""}
                value={orgShortName}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Loc Ref</label>

              <InputComponent
                placeholder="Ej. 12345678901234"
                type="text"
                handler={(e) => {
                  setLocRef(e.target.value);
                  updateChanges("locRef", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={locRef === ""}
                value={locRef}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Rvc Ref</label>

              <InputComponent
                placeholder="Ej. 12345678901234"
                type="number"
                handler={(e) => {
                  setRcvRef(Number(e.target.value));
                  updateChanges("rcvRef", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={rcvRef === ""}
                value={rcvRef}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Employee Ref</label>

              <InputComponent
                placeholder="Ej. 12345678901234"
                type="number"
                handler={(e) => {
                  setEmployeeRef(Number(e.target.value));
                  updateChanges("employeeRef", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={employeeRef === 0}
                value={employeeRef}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Order Type Ref</label>

              <InputComponent
                placeholder="Ej. 12345678901234"
                type="number"
                handler={(e) => {
                  setOrderTypeRef(Number(e.target.value));
                  updateChanges("orderTypeRef", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={orderTypeRef === 0}
                value={orderTypeRef}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Tender Id</label>

              <InputComponent
                placeholder="Ej. 12345678901234"
                type="number"
                handler={(e) => {
                  setTenderId(Number(e.target.value));
                  updateChanges("tenderId", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={tenderId === 0}
                value={tenderId}
              />
            </div>
            <div className={styles.buttonsContainer}>
              {availableDelete && (
                <button
                  onClick={() => {
                    handleRemove();
                  }}
                  className={styles.deleteButton}
                >
                  Eliminar
                </button>
              )}
              <button
                disabled={!updated}
                onClick={() => {
                  setUpdated(true);
                  handleCancel();
                }}
                className={styles.cancelButton}
              >
                Cancelar
              </button>
              <button
                disabled={!updated}
                className={styles.confirmButton}
                onClick={() => {
                  setUpdated(true);
                  handleSave();
                }}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
