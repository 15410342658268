/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCompany, getCompany } from "../../../../reducers/companySlice";
import {
  createFudo,
  deleteFudo,
  getFudo,
  updateFudo,
} from "../../../../utils/services/company";
import { useDispatch } from "react-redux";
import {
  selectLoadingUI,
  loadingOn,
  loadingOff,
} from "../../../../reducers/uiSlice";
import { Notification } from "../../../../containers/notification";
import { useSnackbar } from "notistack";
import { selectUser } from "../../../../reducers/userSlice";
import {
  selectChangesDeposit,
  setChanges,
} from "../../../../reducers/depositSlice";
import { createChangeRecord } from "../../../../reducers/depositChangesSlice";
import dateUtils from "../../../../utils/date";
import { useNavigate } from "react-router-dom";

// CSS

import styles from "./styles.module.css";

//MATERIAL
import InputComponent from "../../../inputs";
import Toolbar from "../../../card/toolbar";
import jwt from "jsonwebtoken";
import config from "../../../../utils/config";
import { Checkbox, Tab, Tabs } from "@material-ui/core";
import { selectSubsidiaries } from "../../../../reducers/subsidiarySlice";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const logo = "https://s3.amazonaws.com/lumarketo.cl/fudotransparente.svg";

export default function FudoComponent() {
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [isTest, setIsTest] = useState(false);
  const [useShippingCost, setUseShippingCost] = useState(false);
  const [productIDForShippingCost, setProductIDForShippingCost] = useState("");
  const [updated, setUpdated] = useState(false);
  const [paymentId, setPaymentId] = useState(1);
  const [subsidiaryId, setSubsidiaryId] = useState();
  const [fudoId, setFudoId] = useState();
  const [useMockOrder, setUseMockOrder] = useState(false);
  const [mockProductId, setMockProductId] = useState("");
  const subsidiaries = useSelector(selectSubsidiaries);
  const company = useSelector(selectCompany);
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoadingUI);
  const changes = useSelector(selectChangesDeposit);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (subsidiaries && subsidiaries.length > 0)
      setSubsidiaryId(subsidiaries[0]._id);
  }, [subsidiaries]);

  useEffect(() => {
    if (company && company._id !== "" && subsidiaryId && subsidiaryId !== "")
      initialize();
  }, [company, subsidiaryId]);

  const initialize = async () => {
    const found = await getFudo(company._id, subsidiaryId).catch((err) => {
      console.log(err);
      return null;
    });
    setClientId("");
    setClientSecret("");
    setUseShippingCost(false);
    setProductIDForShippingCost("");
    setUseMockOrder(found.useMockOrder);
    setMockProductId(found.mockProductId);
    if (found) {
      setFudoId(found._id);
      if (found.subsidiaryId) setSubsidiaryId(found.subsidiaryId);
      if (found.paymentId) setPaymentId(found.paymentId);
      if (found.clientId && found.clientId !== "")
        setClientId(jwt.verify(found.clientId, config.jwtSecret));
      else setClientId("");
      if (found.clientSecret && found.clientSecret !== "")
        setClientSecret(jwt.verify(found.clientSecret, config.jwtSecret));
      else setClientSecret("");
      setIsTest(!!found.isTest);
      if (found.useShippingCost && found.useShippingCost !== "")
        setUseShippingCost(found.useShippingCost);
      if (
        found.productIDForShippingCost &&
        found.productIDForShippingCost !== ""
      )
        setProductIDForShippingCost(found.productIDForShippingCost);
      else setProductIDForShippingCost("");
    }
  };

  const updateChanges = (fieldName, newValue) => {
    const newChanges = [];
    newChanges.push(...changes);
    var index = -1;
    const change = {
      text: "",
      oldValue: null,
      newValue: null,
      date: dateUtils.getUTC().slice(0, 19).replace("T", " "),
    };

    change.newValue = newValue;
    change.oldValue = company && company[fieldName] ? company[fieldName] : "";

    switch (fieldName) {
      case "ClientId":
        change.text = "Cambios en el clientId de fudo";
        break;
      case "ClientSecret":
        change.text = "Cambios en el clientId de fudo";
        break;
      case "paymentId":
        change.text = "Cambios en el id del método de pago de fudo";
        break;
      case "isTest":
        change.text = "Cambios en el modo prueba de fudo";
        break;
      case "useShippingCost":
        change.text = "Cambios en el uso de precio de envío de fudo";
        break;
      case "productIDForShippingCost":
        change.text = "Cambios en el productId de precio de envío de fudo";
        break;
      case "useMockOrder":
        change.text = "Cambios en el uso de mock order de fudo";
        break;
      case "mockProductId":
        change.text = "Cambios en el mockProductId de fudo";
        break;
      default:
        break;
    }

    index = newChanges.findIndex(
      (item) => item.text.toString() === change.text.toString()
    );

    if (index !== -1) {
      change.oldValue = newChanges[index].oldValue;
      newChanges[index] = change;
    } else {
      newChanges.push(change);
    }
    dispatch(setChanges(newChanges));
  };

  const handleCancel = () => initialize();

  const handleSave = async () => {
    dispatch(loadingOn());
    const found = await getFudo(company._id, subsidiaryId).catch((err) => {
      console.log(err);
      return null;
    });
    if (found && !found.clientId) {
      createFudo({
        companyId: company._id,
        clientId: jwt.sign(clientId, config.jwtSecret),
        clientSecret: jwt.sign(clientSecret, config.jwtSecret),
        isTest,
        productIDForShippingCost,
        useShippingCost,
        paymentId: Number(paymentId),
        subsidiaryId,
        useMockOrder,
        mockProductId,
      })
        .then((res) => {
          dispatch(loadingOff());
          getCompany(company._id, dispatch);
          enqueueSnackbar(
            Notification({
              text: "Datos de la tienda actualizados",
              variant: "success",
              withDetails: false,
            })
          );
          setUpdated(false);
          createChangeRecord(
            {
              user: user,
              username: user.username,
              fullName: `${user.firstName} ${user.lastName}`,
              idUser: user.id,
              idCompany: company._id,
              idObject: company._id,
              module: "settings",
              submodule: "fudo",
              createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
              changes: changes,
            },
            user.token,
            dispatch
          ).catch((err) => console.log(err));
        })
        .catch((err) => {
          enqueueSnackbar(
            Notification({
              text: "Error al actualizar datos de la tienda",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
    } else if (found && found.clientId && found)
      updateFudo({
        companyId: company._id,
        clientId: jwt.sign(clientId, config.jwtSecret),
        clientSecret: jwt.sign(clientSecret, config.jwtSecret),
        isTest: isTest,
        productIDForShippingCost,
        useShippingCost,
        paymentId: Number(paymentId),
        subsidiaryId,
        useMockOrder,
        mockProductId,
      })
        .then((res) => {
          dispatch(loadingOff());
          getCompany(company._id, dispatch);
          enqueueSnackbar(
            Notification({
              text: "Datos de la tienda actualizados",
              variant: "success",
              withDetails: false,
            })
          );
          setUpdated(false);
          createChangeRecord(
            {
              user: user,
              username: user.username,
              fullName: `${user.firstName} ${user.lastName}`,
              idUser: user.id,
              idCompany: company._id,
              idObject: company._id,
              module: "settings",
              submodule: "fudo",
              createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
              changes: changes,
            },
            user.token,
            dispatch
          ).catch((err) => console.log(err));
        })
        .catch((err) => {
          enqueueSnackbar(
            Notification({
              text: "Error al actualizar datos de la tienda",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
  };

  const handleRemove = async () => {
    dispatch(loadingOn());

    if (fudoId && fudoId !== "")
      deleteFudo(fudoId)
        .then(async (res) => {
          dispatch(loadingOff());
          getCompany(company._id, dispatch);
          await initialize();
          enqueueSnackbar(
            Notification({
              text: "Datos de la tienda actualizados",
              variant: "success",
              withDetails: false,
            })
          );
          setUpdated(false);
          createChangeRecord(
            {
              user: user,
              username: user.username,
              fullName: `${user.firstName} ${user.lastName}`,
              idUser: user.id,
              idCompany: company._id,
              idObject: company._id,
              module: "settings",
              submodule: "fudo",
              createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
              changes: changes,
            },
            user.token,
            dispatch
          ).catch((err) => console.log(err));
        })
        .catch((err) => {
          enqueueSnackbar(
            Notification({
              text: "Error al actualizar datos de la tienda",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className={styles.parentContainer}>
      <button className={styles.backButton} onClick={() => handleClose()}>
        <span>
          <ArrowBackIcon />
        </span>
        Regresar
      </button>
      <div className={styles.infoContainer}>
        <div className={styles.titleLogoContainer}>
          <Toolbar title="Fudo" />
          <img src={logo} alt="logo" className={styles.imageElement} />
        </div>
        <div className={styles.infoDataContainer}>
          <p className={styles.textDescription}>
            Con las credenciales de FUDO podrás integrar tu sistema
            administrativo de restaurante en uno solo. Esto significa que los
            pedidos que recibas a través de NubeFood serán recibidos
            automáticamente en tu sistema FUDO, permitiéndote tener un control
            centralizado de todas las ventas diarias, así como también la
            generación de una boleta por cada pedido
          </p>

          {subsidiaries && (
            <div>
              <Tabs
                value={subsidiaryId}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, newValue) => setSubsidiaryId(newValue)}
                aria-label="disabled tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                {subsidiaries.map((item) => (
                  <Tab label={item.Name} value={item._id} />
                ))}
              </Tabs>
            </div>
          )}
          <div className={styles.inputStackContainer}>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>ClientID</label>

              <InputComponent
                className={styles.inputElement}
                placeholder="Ej. 12345678901234"
                type="text"
                handler={(e) => {
                  setClientId(e.target.value);
                  updateChanges("ClientId", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={clientId === ""}
                value={clientId}
              />
            </div>

            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>ClientSecret</label>
              <InputComponent
                className={styles.inputElement}
                placeholder="Ej. 12345678901234"
                type="text"
                handler={(e) => {
                  setClientSecret(e.target.value);
                  updateChanges("ClientSecret", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={clientSecret === ""}
                value={clientSecret}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Id del método de pago</label>
              <InputComponent
                className={styles.inputElement}
                placeholder="Ej. 1"
                type="text"
                handler={(e) => {
                  setPaymentId(e.target.value);
                  updateChanges("paymentId", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={paymentId === ""}
                value={paymentId}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Activar modo prueba</label>

              <Checkbox
                checked={isTest}
                onChange={(e) => {
                  setIsTest(e.target.checked);
                  setUpdated(true);
                }}
                value={"isTest"}
                style={{ color: "#122526" }}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>
                Incluir precio de envío
              </label>

              <Checkbox
                checked={useShippingCost}
                onChange={(e) => {
                  setUseShippingCost(e.target.checked);
                  setUpdated(true);
                }}
                value={"useShippingCost"}
                style={{ color: "#122526" }}
              />
            </div>
            {useShippingCost ? (
              <div className={styles.inputContainer}>
                <label className={styles.labelInput}>
                  ProductId para precio de envío
                </label>
                <InputComponent
                  placeholder="Ej. 12345678901234"
                  type="text"
                  handler={(e) => {
                    setProductIDForShippingCost(e.target.value);
                    updateChanges("productIDForShippingCost", e.target.value);
                    setUpdated(true);
                  }}
                  disabled={loading}
                  validation={productIDForShippingCost === ""}
                  value={productIDForShippingCost}
                />
              </div>
            ) : null}
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>
                Crear orden provisional cuando se recibe un pedido con productId
                erróneo
              </label>
              <Checkbox
                checked={useMockOrder}
                onChange={(e) => {
                  setUseMockOrder(e.target.checked);
                  updateChanges("useMockOrder", e.target.checked);
                  setUpdated(true);
                }}
                value={"useMockOrder}"}
                style={{ color: "#122526" }}
              />
            </div>
            {useMockOrder ? (
              <div className={styles.inputContainer}>
                <label className={styles.labelInput}>
                  ProductId para orden provisional
                </label>
                <InputComponent
                  placeholder="Ej. 12345678901234"
                  type="text"
                  handler={(e) => {
                    setMockProductId(e.target.value);
                    updateChanges("mockProductId", e.target.value);
                    setUpdated(true);
                  }}
                  disabled={loading}
                  validation={mockProductId === ""}
                  value={mockProductId}
                />
              </div>
            ) : null}
            <div className={styles.buttonsContainer}>
              {clientId !== "" && (
                <button
                  onClick={() => {
                    handleRemove();
                  }}
                  className={styles.deleteButton}
                >
                  Eliminar
                </button>
              )}
              <button
                disabled={!updated}
                onClick={() => {
                  setUpdated(true);
                  handleCancel();
                }}
                className={styles.cancelButton}
              >
                Cancelar
              </button>
              <button
                disabled={!updated}
                className={styles.confirmButton}
                onClick={() => {
                  setUpdated(true);
                  handleSave();
                }}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
