/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Paper,
} from "@material-ui/core";
import React, { useState, useEffect, Fragment, useRef } from "react";
import { useLocation } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import config from "../../utils/config";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import styles from "./styles.module.css";
import classNames from "classnames";
import uuid from "react-uuid";
import Badg from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { selectCompany } from "../../reducers/companySlice";
import { getUserById, selectUser } from "../../reducers/userSlice";
import { getOrdersByIdCompany, updateStatus } from "../../reducers/orderSlice";
import _orderBy from "lodash/orderBy";
import moment from "moment";
import {
  loadingOff,
  loadingOn,
  selectSubsidiary,
} from "../../reducers/uiSlice";
import orderConstants from "../../constants/order.constants";
import { Notification as ReactNotification } from "../notification";
import { useSnackbar } from "notistack";
import { SelectReason } from "./selectRejectComponent";
// import "FavIconBadge";
import { TabNotification } from "./tabnotification";
// import useFaviconBadgeNotify from 'favicon-badge-notify'

import { Helmet } from "react-helmet";
import PDFOrder from "../../components/orders/pdf.component";
import shippingConstants from "../../constants/shipping.constants";
import paymentConstants from "../../constants/payments.constants";
import FileSaver from "file-saver";
import RefundModal from "./refundModal";
import { getOrdersByIdUser } from "../../utils/services/order";
import lumaUtils from "@lumarketo/luma-utils";
import subsidiariesFunctions from "../../utils/subsidiaries";
import { selectSubsidiaries } from "../../reducers/subsidiarySlice";

const noImage =
  "https://luma-photos.s3.amazonaws.com/photos/Webp.net-resizeimage.png";

export const WebSocketDemo = () => {
  //Public API that will echo messages sent to it back to the client
  const [messageHistory, setMessageHistory] = useState([]);
  const didUnmount = useRef(false);

  const { lastMessage, readyState, sendMessage } = useWebSocket(
    config.websocketUrl,
    {
      shouldReconnect: (closeEvent) => {
        /*
            useWebSocket will handle unmounting for you, but this is an example of a 
            case in which you would not want it to automatically reconnect
          */
        return didUnmount.current === false;
      },
      reconnectAttempts: 10,
      reconnectInterval: 3000,
      onOpen: () => {},
    }
  );
  const [loading, setLoading] = useState(true);
  const [processLoading, setProcessLoading] = useState(false);
  const [newOrderId, setNewOrderId] = useState("");
  const [open, setOpen] = useState(false);
  const [showRedMessage, setShowRedMessage] = useState(false);

  const company = useSelector(selectCompany);
  const user = useSelector(selectUser);
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const subsidiaryId = useSelector(selectSubsidiary);
  const subsidiaries = useSelector(selectSubsidiaries);
  const subsidiary = useSelector(selectSubsidiary);

  const filteredSubsidiaries =
    subsidiariesFunctions.gettingFilteredSubsidiariesByUserAccess(
      user,
      subsidiaries,
      subsidiary
    );
  const isSubsidiaryAutomaticAceptanceActive =
    subsidiariesFunctions.checkSubsidiaryAutomaticAcceptanceStatus(
      filteredSubsidiaries
    );

  const areSubsidiaryWithinWorkingHours =
    subsidiariesFunctions.checkIfSubsidiaryIsInWorkingHours();
  const checkCompanyAutomaticAcceptanceStatus = company?.UseAutoOrderAcceptance;

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    let intervalId;
    if (readyState === ReadyState.OPEN) {
      intervalId = setInterval(() => {
        if (areSubsidiaryWithinWorkingHours) {
          sendMessage(
            JSON.stringify({
              action: "ping",
            })
          );
        } else {
        }
      }, 60000);
    }
    return () => clearInterval(intervalId);
  }, [readyState, sendMessage]);

  useEffect(() => {
    if (!("Notification" in window)) {
    } else {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    if (lastMessage !== null) {
      const parsed = JSON.parse(lastMessage.data);
      if (parsed.order && parsed.order._id !== newOrderId) {
        setNewOrderId(parsed.order._id);
        if (
          parsed.order.idCompany === company._id &&
          (parsed.order.subsidiaryId === subsidiaryId || subsidiaryId === "all")
        ) {
          const found = messageHistory.find(
            (item) => item._id === parsed.order._id
          );
          if (!found) {
            new Notification(
              `Nuevo pedido nro ${parsed.order.companyOrderNumber}`,
              {
                body: `Tiene un nuevo pedido, monto total ${parsed.order.total}`,
                icon: "https://luma-photos.s3.amazonaws.com/content/nubefood.jpeg",
              }
            );
            var audio = document.getElementById("audio");
            if (!company.InfinityOrderAlert) {
              audio.muted = false;
              audio.loop = false;
              audio.play();
            } else {
              audio.muted = false;
              audio.loop = true;
              audio.play();
            }

            setOpen(true);
            setLoading(false);
            setMessageHistory((prev) => prev.concat([{ ...parsed.order }]));
          }
        }
      }
    }
  }, [lastMessage, location.pathname, messageHistory]);

  useEffect(() => {
    if (company && company.InfinityOrderAlert) {
      var audio = document.getElementById("audio");
      if (messageHistory.length > 0 && audio.muted) {
        audio.muted = false;
        audio.loop = true;
        audio.play();
      } else {
        audio.muted = true;
      }
    }
  }, [messageHistory, company]);

  const updateOrders = (useLoading = true) => {
    if (useLoading) {
      dispatch(loadingOn());
      setLoading(true);
    }

    if (company && company._id !== "" && user?.token !== "") {
      getOrdersByIdCompany(
        {
          idCompany: company._id,
          limit: 100,
          page: 1,
          orderBy: "companyOrderNumber",
          order: "desc",
          searchText: "",
          searchField: "",
          byStatus: "picker-pending",
          byPayment: "none",
          byDate: "none",
          startDate: "none",
          endDate: "none",
          subsidiaryId: subsidiaryId,
        },
        user?.token || ""
      )
        .then((orders) => {
          setMessageHistory(orders.items);
          if (orders?.items?.length === 0) setOpen(false);

          dispatch(loadingOff());
          setLoading(false);
        })
        .catch((err) => {
          dispatch(loadingOff());
          setLoading(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    let intervalId;
    let unmounted = false;

    if (company && company._id !== "") {
      if (!unmounted) updateOrders(false);
      intervalId = setInterval(() => {
        if (areSubsidiaryWithinWorkingHours) {
          if (
            isSubsidiaryAutomaticAceptanceActive ||
            checkCompanyAutomaticAcceptanceStatus
          ) {
          } else {
            if (!unmounted) updateOrders(false);
          }
        }
      }, 120000);
    }
    return () => {
      clearInterval(intervalId);
      unmounted = true;
    };
  }, [
    company,
    subsidiaryId,
    isSubsidiaryAutomaticAceptanceActive,
    areSubsidiaryWithinWorkingHours,
    checkCompanyAutomaticAcceptanceStatus,
  ]);

  const windowTime = (ordertime) => {
    const orderTimeCreated = new Date(ordertime);
    return moment(orderTimeCreated).startOf("minute").fromNow();
  };

  const getReason = (reason) => {
    return {
      "sin-stock": `Lamentablemente ${company.Name} se quedó sin stock para tu pedido 😔`,
      "kitchen-close": `Lamentablemente ${company.Name} ya había cerrado la cocina 🥲 . Disculpa por no avisar en la página dicho inconveniente 😞`,
      "kitchen-busy": `Lamentablemente  la cocina de ${company.Name}  está muy ocupada con muchos pedidos  🥲`,
    }[reason];
  };

  const handleUpdateOrder = (
    orderId,
    userId,
    status,
    time,
    shippingDate = new Date().toISOString(),
    observation = ""
  ) => {
    if (user) {
      dispatch(loadingOn());
      setProcessLoading(true);

      getUserById({ id: userId }, user.token)
        .then((u) => {
          var order = {
            status: status,
            observation: getReason(observation),
            id: orderId,
            idPicker: user.id,
            companyId: company._id,
            rejectReason: getReason(observation),
            logo: company.DesktopLogo,
            companyName: company.Name,
            email: u.email,
            name: `${u.firstName} ${u.lastName}`,
            shippingDate,
          };

          if (time) order.addDeliveryTime = time;
          updateStatus(order, user.token, dispatch)
            .then(async (order) => {
              if (status === orderConstants.ORDER_STATUS.cancel.code) {
              } else {
                var path = `boleta-${orderId}.pdf`;

                if (order.boletaUrl !== "")
                  FileSaver.saveAs(order.boletaUrl, path);
              }
              enqueueSnackbar(
                ReactNotification({
                  text: "Pedido actualizado",
                  variant: "success",
                  withDetails: false,
                })
              );
              updateOrders();
              dispatch(loadingOff());
              setProcessLoading(false);
            })
            .catch((err) => {
              setProcessLoading(false);
              dispatch(loadingOff());
              console.log(err);
              if (typeof err === "string")
                enqueueSnackbar(
                  ReactNotification({
                    text: err,
                    variant: "error",
                    withDetails: false,
                  })
                );
              enqueueSnackbar(
                ReactNotification({
                  text: "Error al actualizar el pedido",
                  variant: "error",
                  withDetails: false,
                })
              );
            });
        })
        .catch((err) => {
          setProcessLoading(false);
          dispatch(loadingOff());
          console.log(err);
          return null;
        });
    }
  };

  const SendAccept = (_id, userId, time, date) => {
    handleAccept(_id, userId, time, date, `Aceptado por ${user.email}`);
  };

  const sendRejectReason = (id, orderId, userId) => {
    let rejectReason = document.getElementById(orderId);
    let valuee = rejectReason.value;
    // let valuee = "kitchen-close"

    if (valuee !== "") {
      // console.log("si hay razon puedo enviar");
      handleDecline(id, userId, valuee);
    } else {
      // console.log("debes seleccionar la razón");
      setShowRedMessage(true);
    }
  };

  const handleAccept = (orderId, userId, time, date, observation = "") =>
    handleUpdateOrder(
      orderId,
      userId,
      orderConstants.ORDER_STATUS.inProcess.code,
      time,
      date,
      observation
    );
  const handleDecline = (orderId, userId, observation = "") =>
    handleUpdateOrder(
      orderId,
      userId,
      orderConstants.ORDER_STATUS.cancel.code,
      undefined,
      undefined,
      observation
    );

  return (
    <Fragment>
      <Helmet>
        <link
          rel="icon"
          type="image/png"
          sizes="15x15"
          href="https://s3.amazonaws.com/haya.lumarketo.cl/favicon.ico"
        />
        {/* <title>TIENES PEDIDOS</title> */}
      </Helmet>

      <IconButton
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Badg
          variant={messageHistory.length > 0 ? "dot" : "standard"}
          color="error"
        >
          <NotificationsIcon style={{ color: "#fff" }} />
        </Badg>
      </IconButton>

      <Drawer
        anchor={"right"}
        open={open}
        onClose={() => setOpen(false)}
        className={styles.sideBarRoot}
      >
        <TabNotification messageHistory={messageHistory} />
        <Paper
          className={classNames(
            styles.paperSideBar,
            messageHistory.length > 0 ? "" : styles.notFoundPaperPopover
          )}
          style={{
            backgroundColor: "#fff",
          }}
        >
          <Grid container direction="row" justifyContent="right">
            <Grid item xs={12}>
              <IconButton onClick={() => setOpen(false)}>
                <CloseOutlined />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={12}
              className={styles.cartListSidebar}
              justifyContent="center"
            >
              {loading ? (
                <div className="row">
                  {" "}
                  <div className="col-12 text-center">
                    <CircularProgress style={{ color: "#000" }} />{" "}
                  </div>{" "}
                </div>
              ) : null}
              {!loading &&
                _orderBy(messageHistory, "companyOrderNumber", "asc").map(
                  (item, index) => (
                    <RenderDetails
                      key={`${item._id}-${index}`}
                      {...{
                        item,
                        company,
                        SendAccept,
                        windowTime,
                        processLoading,
                        sendRejectReason,
                        showRedMessage,
                      }}
                    />
                  )
                )}
            </Grid>
          </Grid>
        </Paper>
      </Drawer>
    </Fragment>
  );
};

const RenderDetails = ({
  item,
  company,
  SendAccept,
  windowTime,
  processLoading,
  sendRejectReason,
  showRedMessage,
}) => {
  const [time, setTime] = useState(shippingConstants.SHIPPING_TIME.Inmediato);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [newUser, setNewUser] = useState(false);

  useEffect(() => {
    async function getOrders() {
      const orders = await getOrdersByIdUser(item.idUser, "").catch((err) => {
        console.log(err);
        return null;
      });
      setNewUser(orders.length === 1);
    }
    if (item.idUser) {
      getOrders();
    }
  }, [item]);

  const getPayment = () => {
    if (item.payment === "mercadopago" && item.total !== 0)
      return (
        <img
          src="https://s3.amazonaws.com/lumarketo.cl/mercadopago-svg-payments.svg"
          alt="mercadopago"
          width="85px"
        />
      );
    else if (item.payment === "getnet" && item.total !== 0) {
      return (
        <img
          src="https://banco.santander.cl/uploads/000/015/050/0affa7e1-10e5-45ff-b0e3-7616aee7d686/original/getnet_logo.svg"
          alt="getnet"
          width={85}
        />
      );
    } else if (item.total === 0) {
      return <strong>Sin método de pago debido a cupones y descuentos</strong>;
    } else
      return (
        <strong>
          {paymentConstants.TYPES_NAMES[item.payment] || "No definido"}
        </strong>
      );
  };

  return (
    <div className={styles.detailsContainer} key={item._id}>
      <p className={styles.orderTimeDate}>{windowTime(item.createdDate)}</p>

      <div className={styles.orderCartGeneralContainerBox}>
        <div className={styles.orderCartContainer}>
          <div className={styles.orderCartIconSideBar}>
            <img
              className={styles.iconSideCartOrder}
              width="100%"
              src={
                item.products[0].skuInfo.Imgs.length > 0 &&
                item.products[0].skuInfo.Imgs[0].Path &&
                item.products[0].skuInfo.Imgs[0].Path !== ""
                  ? item.products[0].skuInfo.Imgs[0].Path
                  : noImage
              }
              alt=""
            />
          </div>
          <div>
            <p className={styles.orderCartText}>
              {" "}
              Haz recibido un pedido{" "}
              <strong> #({item.companyOrderNumber})</strong> de{" "}
              <strong>{item.customerName} </strong>
              de un total de{" "}
              <strong>
                {" "}
                ${lumaUtils.numberUtils.formatNumber(item.total)}
              </strong>{" "}
              con {item.products.length} producto(s)
            </p>
          </div>
        </div>

        {/* Pedido nro: {item.companyOrderNumber} */}
      </div>
      <div className={styles.ItemsDetailsContainer}>
        <p className={styles.detailsTitleText}>DETALLES DEL PEDIDO</p>
        {item.products.map((product, index) => {
          return (
            <div key={`${product._id}-${index}`}>
              <div className={styles.itemDetailContainerSideCart}>
                <div>
                  <p className={styles.itemDetailNameSideCart}>
                    {" "}
                    {product.quantity}
                  </p>
                </div>

                <div>
                  <p className={styles.itemDetailNameSideCart}>
                    {" "}
                    {product.productInfo.Name}
                    {product.skuInfo.Size !== ""
                      ? ` - ${product.skuInfo.Size}`
                      : ""}
                  </p>
                </div>
              </div>

              {product.additionals.length > 0 && (
                <p className={styles.itemDetailNameSideCart}>Con:</p>
              )}

              {product.additionals.length > 0 &&
                product.additionals.map((aditional, index) => {
                  return (
                    <p
                      key={uuid()}
                      className={styles.itemDetailNameSideCartExtra}
                    >
                      {aditional.description}
                    </p>
                  );
                })}

              {product.instructions !== "" && (
                <div>
                  <p>
                    {" "}
                    Instrucciones especiales:{" "}
                    <span className={styles.itemDetailNameSideCart}>
                      {product.instructions}
                    </span>
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.orderInformation}>
        {item.address && item.address.address && (
          <p>
            Dirección:{" "}
            <span className={styles.itemDetailNameSideCart}>
              {`${item.address.address}, ${item.address.streetNumber}, ${item.address.details}`}
            </span>
          </p>
        )}
        <p>Método de pago: {getPayment()}</p>
        <p>
          Método de Envío:{" "}
          {item.shippingPolicy &&
            item.shippingPolicy.fulfillmentId === "Pedidosya" && (
              <img
                src="https://www.ccs.cl/wp-content/uploads/2022/10/PedidosYa-Envi%CC%81os_rojo-vertical.png"
                alt="pedidosYa"
                width="85px"
              />
            )}
          {item.shippingPolicy &&
            item.shippingPolicy.fulfillmentId === "Cabify" && (
              <img
                src="https://s3.amazonaws.com/haya.lumarketo.cl/Cabify-Logo-Moradul-RGB.png"
                alt="cabify"
                width="85px"
              />
            )}
          {item.shippingPolicy &&
            item.shippingPolicy.fulfillmentId === "Uber" && (
              <img
                src="https://dgdvd9d8e5bk8.cloudfront.net/uberdireclogowebp.webp"
                alt="uber"
                width="85px"
              />
            )}
          {item.shippingPolicy &&
            item.shippingPolicy.fulfillmentId !== "Cabify" &&
            item.shippingPolicy.fulfillmentId !== "Pedidosya" &&
            item.shippingPolicy.fulfillmentId !== "Uber" && (
              <span className={styles.itemDetailNameSideCart}>
                {" "}
                Envío propio
              </span>
            )}
          {!item.shippingPolicy && item.subsidiary && (
            <span className={styles.itemDetailNameSideCart}>
              {" "}
              Retiro en restaurant
            </span>
          )}
        </p>
        <p>
          Cliente:{" "}
          {newUser ? (
            <span className={styles.itemDetailNameSideCart}>Nuevo</span>
          ) : (
            <span className={styles.itemDetailNameSideCart}>Recurrente</span>
          )}
        </p>
        {item?.fiscalDocument &&
          item?.fiscalDocument?.documentType &&
          item?.fiscalDocument?.documentNumber && (
            <p className={styles.fiscalDocumentLabel}>
              **Pedido con {item?.fiscalDocument?.fiscalDocumentType}**
            </p>
          )}
      </div>
      <div className={styles.containersAcceptRejectOrder}>
        <p className={styles.buttonsContainerRejectOrderSideCart}>
          <div
            className={styles.containerRejectOrderSideCartSubContainerButtons}
          >
            {company.PrintOrder ? (
              <PDFOrder
                createdDate={lumaUtils.dateUtils.momentLib
                  .tz(item.createdDate, "America/Santiago")
                  .format()}
                model={company.TicketType}
                title={lumaUtils.stringUtils.slugifyText(
                  company.Name,
                  " ",
                  false
                )}
                address={
                  item.subsidiary &&
                  item.subsidiary.AddressInfo &&
                  lumaUtils.stringUtils.slugifyText(
                    item.subsidiary.AddressInfo.address,
                    " ",
                    false
                  )
                }
                tlf={(item.subsidiary && item.subsidiary.Tlf) || ""}
                orderNumber={item.companyOrderNumber}
                products={item.products}
                total={item.total}
                userAddress={
                  item.address
                    ? lumaUtils.stringUtils.slugifyText(
                        `${item.address.address}, ${item.address.streetNumber}, ${item.address.details}`,
                        " ",
                        false
                      )
                    : item.subsidiary && !item.shippingPolicy
                    ? "Sin direccion"
                    : "Sin direccion"
                }
                fullName={
                  item.user
                    ? lumaUtils.stringUtils.slugifyText(
                        `${item.user.firstName} ${item.user.lastName}`,
                        " ",
                        false
                      )
                    : "No disponible"
                }
                userTlf={item.user ? item.user.tlf : "No definido"}
                linkStyle="editLabel"
                disabled={processLoading}
                linkText="Hacer click para descargar"
                companyLogo={company.DesktopLogo}
                shippingCost={item.shippingCost}
                shipping={
                  item.shippingPolicy && item.shippingPolicy.name
                    ? lumaUtils.stringUtils.slugifyText(
                        item.shippingPolicy.name,
                        " ",
                        false
                      )
                    : item.shippingPolicy && item.shippingPolicy.fulfillmentId
                    ? lumaUtils.stringUtils.slugifyText(
                        item.shippingPolicy.fulfillmentId,
                        " ",
                        false
                      )
                    : item.subsidiary
                    ? "Retiro en sucursal"
                    : "No definido"
                }
                distance={(item.subsidiary && item.subsidiary.Distance) || 0}
                buttonStyle={styles.acceptOrderButtom}
                onClick={() => {
                  SendAccept(item._id, item.idUser, time, item.shippingDate);
                }}
                buttonText={
                  processLoading ? (
                    <div className="col-12 text-center">
                      <CircularProgress
                        style={{
                          color: "#fff",
                          height: 15,
                          width: 15,
                        }}
                      />{" "}
                    </div>
                  ) : (
                    "Aceptar"
                  )
                }
              />
            ) : (
              <button
                onClick={() => {
                  SendAccept(item._id, item.idUser, time, item.shippingDate);
                }}
                className={styles.acceptOrderButtom}
                disabled={processLoading}
              >
                {processLoading ? (
                  <div className="col-12 text-center">
                    <CircularProgress
                      style={{
                        color: "#fff",
                        height: 15,
                        width: 15,
                      }}
                    />{" "}
                  </div>
                ) : (
                  "Aceptar"
                )}
              </button>
            )}
          </div>
          {item.shippingPolicy &&
            item.shippingPolicy.fulfillmentId !== "" &&
            !item?.useScheduling && (
              <div
                className={
                  styles.containerRejectOrderSideCartSubContainerLabelsInputs
                }
              >
                <p className={styles.labelRejectAceptOrder}>
                  ¿Cuando quieres buscar el delivery?
                </p>
                <select
                  value={time}
                  onChange={(e) => {
                    setTime(e.target.value);
                  }}
                  className={styles.rejectReasonSelect}
                >
                  <option value="" disabled selected>
                    Selecciona el tiempo de espera para el envío
                  </option>
                  <option value={shippingConstants.SHIPPING_TIME.Inmediato}>
                    Inmediato
                  </option>
                  <option value={shippingConstants.SHIPPING_TIME["10min"]}>
                    10min
                  </option>
                  <option value={shippingConstants.SHIPPING_TIME["15min"]}>
                    15min
                  </option>
                  <option value={shippingConstants.SHIPPING_TIME["20min"]}>
                    20min
                  </option>
                  <option value={shippingConstants.SHIPPING_TIME["25min"]}>
                    25min
                  </option>
                  <option value={shippingConstants.SHIPPING_TIME["30min"]}>
                    30min
                  </option>
                  <option value={shippingConstants.SHIPPING_TIME["45min"]}>
                    45min
                  </option>
                  <option value={shippingConstants.SHIPPING_TIME["60min"]}>
                    60min
                  </option>
                </select>
              </div>
            )}

          {item?.useScheduling && item?.shippingDate && (
            <div
              className={
                styles.containerRejectOrderSideCartSubContainerLabelsInputs
              }
            >
              <p className={styles.labelRejectAceptOrder}>
                Pedido programado para :{" "}
                <span className={styles.itemDetailNameSideCart}>
                  {lumaUtils.dateUtils
                    .momentLib(item?.shippingDateLambda ?? undefined)
                    .tz("America/Santiago")
                    .format("DD/MM/YYYY HH:mm")}
                </span>
              </p>
            </div>
          )}
        </p>
        <p className={styles.buttonsContainerRejectOrderSideCart}>
          <div
            className={styles.containerRejectOrderSideCartSubContainerButtons}
          >
            <button
              onClick={
                () => setOpenRefundModal(true)
                // sendRejectReason(item._id, item.companyOrderNumber, item.idUser)
              }
              className={styles.rejectOrderButtom}
              disabled={processLoading}
            >
              {processLoading ? (
                <div className="col-12 text-center">
                  <CircularProgress
                    style={{
                      color: "#fff",
                      height: 15,
                      width: 15,
                    }}
                  />
                </div>
              ) : (
                "Rechazar"
              )}
            </button>
            {/* <select id={item.companyOrderNumber} onChange={(e) => { handleRejectReason(e) }} className={styles.rejectReasonSelect}>
                            <option value="" disabled selected>Selecciona la razón</option>
                            <option value="sin-stock">Sin stock</option>
                            <option value="kitchen-close">Cocina cerrada</option>
                            <option value="kitchen-busy">Cocina muy ocupada</option>
                        </select> */}
          </div>
          <div
            className={
              styles.containerRejectOrderSideCartSubContainerLabelsInputs
            }
          >
            <p className={styles.labelRejectAceptOrder}>
              Indica la razón del rechazo
            </p>
            <SelectReason id={item.companyOrderNumber} />
          </div>
        </p>
        {showRedMessage && (
          <p style={{ color: "red" }}>
            Debes seleccionar la razón del rechazo para notificarle al cliente.
          </p>
        )}
      </div>
      <RefundModal
        loading={processLoading}
        open={openRefundModal}
        handleClose={() => setOpenRefundModal(false)}
        orderNumber={item.companyOrderNumber}
        handleAccept={() =>
          sendRejectReason(item._id, item.companyOrderNumber, item.idUser)
        }
        fullName={item.user ? item.user.firstName : "No definido"}
        operationId={
          item.getnetRequestId !== ""
            ? item.getnetRequestId
            : item.mercadopagoPaymentId
        }
        paymentMethod={item.payment}
      />
    </div>
  );
};
